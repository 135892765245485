var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;



if ($('.lc-booking-form-container').length) {

  window.iniRadioComment = function() {
    $('.radio-form-comment').each(function(index) {
      var $this = $(this); 
      // Get the value of the element
      var eltValue = $this.attr('data-value');
      // Get the data-target
      var eltTarget = $this.attr('data-target');
      var $eltTarget = $('#' + eltTarget + '');
      // Set checked
      if ($eltTarget.val() == eltValue) $this.prop('checked',true);
      else $this.prop('checked',false);
    });

    iniRadioService();
  };

  $(document).on('change','.radio-form-comment', function(e) {
    var $this = $(this);
    // Get the value of the element
    var eltValue = $this.val();
    // Update the data-target 
    var eltTarget = $this.attr('data-target');
    var $eltTarget = $('#' + eltTarget + '');
    var commentVar = eltValue;
    $eltTarget.val(commentVar);
  });

  window.iniRadioService = function() {
    $('.radio-form-service').each(function(index) {
      var $this = $(this); 
      // Get the value of the element
      var eltValue = $this.attr('data-value');
      // Get the data-target
      var eltTarget = $this.attr('data-target');
      var $eltTarget = $('#' + eltTarget + '_quantity');
      // Set checked
      if ($eltTarget.val() == eltValue) $this.prop('checked',true);
      else $this.prop('checked',false);
    });
  };

  $(document).on('change','.radio-form-service', function(e) {
    var $this = $(this);
    // Get the value of the element
    var eltValue = $this.val();
    // Update the data-target 
    var eltTarget = $this.attr('data-target');
    var $eltTarget = $('#' + eltTarget + '');
    var commentVar = eltValue;
    $('#' + eltTarget + '_quantity').val(commentVar);
    calculatePriceTime();
  });
  

}
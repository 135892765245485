var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;
var globalLoaderCode = globals.loaderCode;

if ($('.lc-booking-form-container').length) {	

	
	// -----------------------------------------
	// Reset All Steps and sub steps
	// -----------------------------------------
	window.resetAllStepsSubSteps = function()  {
		// Hide all loaders
		hideLCLoader();
		// Reset classes for the step
		$('.LC_step').removeClass('edit resume active');
		// Reset classes for all childrens of the step
		$('.LC_step .edit').removeClass('edit'); 
		$('.LC_step .resume').removeClass('resume');
		$('.LC_step .active').removeClass('active');
		$('.LC_step_subs .LC_resume').removeClass('hidden');
		// First step case
		$('#LC_step_1 .LC_resume').removeClass('active');
		$('#LC_footer').removeClass('hide');
		$('#LC_footer .price').html(' - ');
		aos.init({
        offset: 50,
        delay: 0,
        duration: 850
    });
	}

	// -----------------------------------------
	// Resume To Edit
	// -----------------------------------------
	window.resumeToEdit = function(stepNr,subStepNr) {
	}

	// -----------------------------------------
	// Set step to edit
	// -----------------------------------------
	window.setStepToEdit = function(stepNr,subStepNr) {

	//	console.log(stepNr + '----' + subStepNr);

		// Track step
    gatrack.trackStep($('.lc-booking-form-container').data('service'),stepNr,subStepNr);

		// Reset classes for all steps and substeps
		resetAllStepsSubSteps(stepNr,subStepNr);
		
		// Set specific startAction for the active step
		startActionStep(stepNr,subStepNr);

		// Show all steps before in resume
		for (i=1;i<stepNr;i++) {
			$('#LC_step_'+i).addClass('resume');
		}

		//	// console.log('check ajx in LC_step_' + stepNr)
		// Show loader if ajx is present
		if ($('#LC_step_' + stepNr).find('.ajx').length) {
			// // console.log('ajx found')
			// Loader to show
			$show = $('#LC_step_' + stepNr + ' .LC_loader');
			// Step Edit to hide
			if (stepNr == 1) {
				$hide = $('#LC_step_' + stepNr + ' .LC_resume'); // step to hide for the first step
			} else {
				if ($('#LC_step_' + stepNr).hasClass('LC_step_subs')) $hide = $('#LC_step_' + stepNr + '_edit_' + subStepNr); // sub step to hide 
				else $hide = $('#LC_step_' + stepNr + '_edit');
			}
			showLCLoader($show, $hide);
		}
		// // console.log('console 2 : setStepToEdit continue');
		// Case first step
		if (stepNr == 1) {
			$('#LC_step_' + stepNr).removeClass('edit').addClass('resume active'); // set step to resume active
			$('#LC_step_' + stepNr + ' .LC_resume').addClass('active'); // set LC_resume to active
		} else {
			$('#LC_step_' + stepNr).removeClass('resume').addClass('edit active'); // Set the step to edit active
			if ($('#LC_step_'+stepNr).hasClass('LC_step_subs')) { // Check if there are some substeps
				setSubStepToEdit(stepNr,subStepNr); 
			}
		}

		// Scroll step into view
    if ($('#LC_step_' + stepNr + '_edit_' + subStepNr).length > 0) {
      scrollIntoViewIfNeeded($('#LC_step_' + stepNr + '_edit_' + subStepNr));
		} else {
        scrollIntoViewIfNeeded($('#LC_step_' + stepNr));
		}
		
		// Set the status and text for the footer button
		setSubmitBtn(stepNr);
	}

	// Always scroll to LC_step_2 active, INIT reaction.
	var firstScroll = 0;
	if($('#f_quantity').val() != 'undefined'){
        $('.lc-booking-form-container').bind('DOMSubtreeModified', function(e) {
            if(e.target.innerHTML.length > 0) {
                if($('#LC_step_2_edit_1').hasClass('active') === true){
                    if(firstScroll === 0){
                        firstScroll = 1;
                        $('.lc-booking-form-container').off('DOMSubtreeModified');
                        setTimeout(function(){
                            scrollIntoViewIfNeeded($('#LC_step_2_edit_1'));
                        }, 600);
                    }
                }
            }
        });
	}


	// -----------------------------------------
	// Set selected sub step to edit
	// -----------------------------------------
	window.setSubStepToEdit = function(stepNr,subStepNr) {
		// // console.log('setSubStepToEdit: ' + stepNr + ' - ' + subStepNr)
		// Set the first substep active by default
		if (subStepNr == null) { 
			$('#LC_step_' + stepNr + '.LC_step_subs .LC_edit .LC_edit_sub').first().addClass('edit active'); // Show the first sub step edit
			$('#LC_step_' + stepNr + '.LC_step_subs .LC_resume').addClass('hidden'); // Hide the first sub step resume
		// set the selected substep active
		} else {
			for (i=1;i<subStepNr;i++) {
				$('#LC_step_' + stepNr + '_resume_' + i).addClass('resume'); // Set step before selected substep i resume 
			}
			$('#LC_step_' + stepNr + '_edit_' + subStepNr).addClass('edit active'); // set the selected substep to active
			if (subStepNr == 1) $('#LC_step_' + stepNr + ' .LC_resume').addClass('hidden');	// Hide the resume if the first step
		}

	}




    // -----------------------------------------
	// Set selected step to active
	// -----------------------------------------
	/*
	window.setStepToActive = function(stepNr,subStepNr) {
		
		// console.log('function setStepToActive: ' + stepNr + ' - ' + subStepNr);
		
		// Reset classes for all steps and substeps
		resetAllStepsSubSteps(stepNr,subStepNr);
		
		// Show all steps before in resume
		for (i=1;i<stepNr;i++) {
			$('#LC_step_'+i).addClass('resume');
		}
		
		// set wait class to ajax class in the step
		if ($('#LC_step_'+stepNr).hasClass('LC_step_subs')) {
			$('#LC_step_' + stepNr + '_edit_' + subStepNr + ' .ajax').addClass('wait');
		} else {
			$('#LC_step_' + stepNr + ' .ajax').addClass('wait');
		}
		
		// set the class Loader 
		stepLoader(stepNr);

		// Set the selected step to active and edit
		if (stepNr == 1) {
			$('#LC_step_' + stepNr).addClass('resume active');
			$('#LC_step_' + stepNr + ' .LC_resume').addClass('active');			
			//$('#LC_step_' + stepNr + ' .LC_resume').addClass(classLoader);
		} else {
			$('#LC_step_' + stepNr).removeClass('resume').addClass('edit active');
		}
		// Check if there are some substeps
		if ($('#LC_step_'+stepNr).hasClass('LC_step_subs')) {
			setSubStepToActive(stepNr,subStepNr);
		}

		// Set special startAction
		startActionStep(stepNr,subStepNr);
		// Set the status and text for the footer button
		setSubmitBtn(stepNr);


		// Set the selected step to active and edit
		if (stepNr == 1) {
			$('#LC_step_' + stepNr).addClass('resume active');
			$('#LC_step_' + stepNr + ' .LC_resume').addClass('active');
			// Show the loader
			
		}
		else $('#LC_step_'+stepNr).removeClass('resume').addClass('edit active');
		// Check if there is substeps
		if ($('#LC_step_'+stepNr).hasClass('LC_step_subs')) {
			setSubStepToActive(stepNr,subStepNr);
		}
		
		
		
		// Set the status and text for the footer button
		setSubmitBtn(stepNr);
		
	} */

	// -----------------------------------------
	// set the LC_step current in edit mode
	// -----------------------------------------
	/*window.setSubStepToActive = function(stepNr,subStepNr) {
		// Set the first substep active by default
		if (subStepNr == null) {
			// Show the first sub step edit 
			$('#LC_step_' + stepNr + '.LC_step_subs .LC_edit .LC_edit_sub').first().addClass('edit active');
			// Hide the first sub step resume
			$('#LC_step_' + stepNr + '.LC_step_subs .LC_resume').addClass('hidden');
		}
		// set the selected substep active
		else {
			// Set step before selected substep i resume 
			for (i=1;i<subStepNr;i++) {
				$('#LC_step_' + stepNr + '_resume_' + i).addClass('resume');
			}
			// set the selected substep to active
			$('#LC_step_' + stepNr + '_edit_' + subStepNr).addClass('edit active');
			// Hide the resume if the first step
			if (subStepNr == 1) $('#LC_step_' + stepNr + ' .LC_resume').addClass('hidden');		
		}
	}*/

	// -----------------------------------------
	// Set status and text for the footer button
	// -----------------------------------------
	window.setSubmitBtn = function(stepNr) {
		// // console.log(' -> function setSubmitBtn');
		var $this = $('#LC_step_' + stepNr);
		var $dataStep = 'LC_step_' + stepNr;
		// Reset the submitBtn by default
		$('#LC_footer .submitBtn').addClass('disabled');
		setDataAttr($('#LC_footer .submitBtn'),'data-step',$dataStep);
		// Update the submit btn label if necessary
		if ($this.find('.submitBtnTxt').length > 0) {
			var submitBtnTxt = $this.find('.submitBtnTxt').data('txt');
			$('#LC_footer .submitBtn span').html(submitBtnTxt);
		}
		// Update the footer btn if necessary
		if ($this.hasClass('newSubmitBtn')) {
			$('#LC_footer .submitBtn').removeClass('disabled');
		}
		// Show or hide the footer
		if ($this.hasClass('no-footer')) $('#LC_footer').addClass('hide');
		else $('#LC_footer').removeClass('hide');
		// Show or hide the price in the footer
		if ($this.hasClass('no-footer-price')) $('#LC_footer .price_info .price').addClass('hide');
		else $('#LC_footer .price_info .price').removeClass('hide');
		// Is it the end?
		var nextStep = getNextStep(stepNr);
		var $end = 0;
    if (nextStep[0] == 0) $end = 1;
		
		// Calculate the price
		calculatePriceTime($end);

		windowUpdate();

	}

	// -----------------------------------------
	// Update the resume 
	// -----------------------------------------
	window.updateStepResume = function($this) {
		// Set variables
		var stepID = $this.data('step');
		var dataStepResume = stepID.replace('edit','resume');
		var dataStepResumeTxt = $this.attr('data-resumetxt');
		// set the right text into the resume btn
		$('#' + dataStepResume + ' .editBtn').not('.no-edit').html(dataStepResumeTxt);
	}

	// -----------------------------------------
	// Get the next next step and substep number 
	// -----------------------------------------
	window.getNextStep = function(stepNr,subStepNr) {
		
		var nextStepNr = null;
		var nextSubStepNr = null;
		
		if ($form.find('.LC_edit_sub.active').length > 0) {
			nextSubStepNr = parseInt(subStepNr,10) + 1;
			if ($('#LC_step_' + stepNr + '_edit_'+ nextSubStepNr).length > 0) {
				nextStepNr = stepNr;
				nextSubStepNr = nextSubStepNr;
			} else {
				nextStepNr = parseInt(stepNr,10) + 1;
				//nextSubStepNr = subStepNr;
                nextSubStepNr = 1;
			}
		} else {
			nextStepNr = parseInt(stepNr,10) + 1;
			if ($('#LC_step_'+ nextStepNr).length > 0) {
				nextStepNr = nextStepNr;
				nextSubStepNr = 1;
			} else {
				// This is the end
				nextStepNr = 0;
				nextSubStepNr = 0;
			}
		}
		return [nextStepNr, nextSubStepNr];

	}

	// -----------------------------------------
	// Set button to selected
	// -----------------------------------------
	window.setNextBtnToselected = function($this,stepNr,subStepNr) {

		if ($form.find('.LC_edit_sub.active').length > 0) {
			$('#LC_step_' + stepNr + '_edit_'+ subStepNr + ' .nextBtn').removeClass('selected');
		} else {
			$('#LC_step_'+ nextStepNr + ' .editBtn').removeClass('selected');
		}
		$this.addClass('selected');

	}

	// -----------------------------------------
	// 
	// -----------------------------------------
	window.actionStepTyp = function($this) {
		// Set variables
		var stepID = $this.data('step');
		// // console.log('actionStepTyp: ' + stepID);
		var dataStepResume = stepID.replace('edit','resume');
		var dataStepResumeTxt = $this.attr('data-resumetxt');
		// set the right text into the resume btn
		$('#' + dataStepResume + ' .editBtn').html(dataStepResumeTxt);
		// Specific end Action
		// Get the active step nr
		stepIDArr = stepID.split('_');
		var activeStepNr = ''; 
		var activeSubStepNr = '';
		if (stepIDArr.length > 2) activeStepNr = stepIDArr[2];
		if (stepIDArr.length > 4) activeSubStepNr = stepIDArr[4];
		// Go to endAction 
		endActionStep($this,activeStepNr,activeSubStepNr);
	}

	// -----------------------------------------
	// Re init the 
	// -----------------------------------------
	window.reinitForm = function()  {
		// Set variables
		var selectedService = $('.lc-booking-form-container form').data('service');
		// Set selected cat to pri by default
        var selectedCat = 'pri';
        if($('.lc-booking-form-container form').data('cat')){
            selectedCat = $('.lc-booking-form-container form').data('cat');
		}
		var dataStr = JSON.stringify($form.serializeArray());
		var ajaxURL = globalUrl + 'tw/' + selectedCat + '/forms/' + selectedService + '/post-init-form.cfm';
		// Ajax call
		$.ajax({
      url : ajaxURL,
      type: "POST",
      data :{dataStr:dataStr},
      dataType: 'json',
      success: function(data, textStatus, jqXHR) {
      	if (data == true) {
      		initForm(); // Init the form
      	} else {
      		showAlertError();
      	}
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // error handling
        showAlertError();
      }
    });
  }


  
  


}
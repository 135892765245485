var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;
var globalIndex = globals.siteIndex;



if ($('.lc-booking-form-container').length) {


	// -----------------------------------------
	// Action on LC_step_1: slider inside form
	// -----------------------------------------
	$(document).on('click', '#f_submit_slider', function(e) {
    e.stopImmediatePropagation();  
    if ($('.lc-booking-form-container form').valid()) {
      // // console.log('form valid & reinitForm');
      $('input').blur(); // remove focus from the form
      $('#LC_step_1 .field').addClass('has-success'); // Set class has-success to all fields in the step 1
      reinitForm();
    }
    return false; 
  });

  // Focus element on all step 1
  $(document).on('focus','#LC_step_1 button, #LC_step_1 input', function(e) {
    e.stopImmediatePropagation();
    if (!$('#LC_step_1 .LC_resume').hasClass('active')) {
      $('#LC_step_1 .field').removeClass('has-success');
      setStepToEdit(1);
    }
  });

  // Event key up
  $(document).on('keyup', '#f_quantity, #c__delivery_address__postal_code', function(e) {
    e.stopImmediatePropagation(); 
    if (e.which == 13) {
      $('#f_submit_slider').trigger("click");
    }
  });

  // Event selectpicker change
  $(document).on('change', '.lc-booking-form-container form #f__handler', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    var selected = $this.find('option:selected');
    var cat = $('.lc-booking-form-container form').data('cat');
    if (cat == 'pri') loadURL = globalUrl + globalIndex + 'for-privatpersoner/' + selected.data('service');
    window.location.href = loadURL; 
  });

  // -----------------------------------------
  // Action on extra service button
  // -----------------------------------------
  $(document).on('touchstart click','.btn-extra-services, .extraService', function(e) {
    e.stopImmediatePropagation();
    var selectedService = $(this).closest('.extraService').attr('id');
    var cat = $('.lc-booking-form-container form').data('cat');
    if (cat == 'pri') loadURL = globalUrl + 'index.cfm/for-privatpersoner/' + selectedService + '/';
    window.location.href = loadURL; 
  });

  // -----------------------------------------
  // Action on nextBtn
  // -----------------------------------------
  $(document).on('click','.nextBtn', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    if ($form.valid()) {
      // get the current LC step nummer
      dataStep = $(this).data('step'); 
      var currentStepArr = getStepNr(dataStep);

      // Set this button to selected and remove other selected class for the same step or sub-step
      setNextBtnToselected($this, currentStepArr[0], currentStepArr[1]);
      // Update the LC_resume
      updateStepResume($this); 
      // Set what should be done at the end of this current step
      endActionStep($this, currentStepArr[0], currentStepArr[1]);
      // Get the next step and set to edit
      var nextStep = getNextStep(currentStepArr[0], currentStepArr[1]);
      setStepToEdit(nextStep[0],nextStep[1]);
    }
  });



    // -----------------------------------------
  // Action on submitBtn
  // -----------------------------------------
  $(document).on('click','.submitBtn', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    if ($form.valid()) {
      // get the current LC step nummer
      dataStep = $(this).data('step'); 
      var currentStepArr = getStepNr(dataStep);
      // Update the LC_resume
      // updateStepResume($this); 
      // Set what should be done at the end of this current step
      endActionStep($this, currentStepArr[0], currentStepArr[1]);
      // Is this the last step
      if ($('#LC_step_' + (parseInt(currentStepArr[0],10) + 1)).length > 0) {
        // Get the next step and set to edit
        var nextStep = getNextStep(currentStepArr[0], currentStepArr[1]);
        //  // console.log('nextStep: ' + nextStep[0] + ' - ' + nextStep[1] )
        setStepToEdit(nextStep[0],nextStep[1]);
      } else {
        //  // console.log('time to submit the form');
        sendForm();
      }
      
    }
  });


  // -----------------------------------------
  // Re init the 
  // -----------------------------------------
  window.sendForm = function()  {
    
    // Hide necessary steps
    $('.LC_step').hide();
    $('#LC_footer').addClass('hide');
    $('#extraServices').addClass('hide');
    // Show waiting loader
    $('.LC_wait').addClass('show');
    $('.LC_wait .LC_loader').addClass('show-loader');


    scrollIntoViewIfNeeded($('#form-steps'));
    
    
    // Set variables
    var $form = $('.lc-booking-form-container form');
    var selectedService = $form.data('service');
    var selectedCat = 'pri';

    if($form.data('cat')){
        selectedCat = $form.data('cat');
    }
    
    var dataStr = JSON.stringify($form.serializeArray());
    var ajaxURL = globalUrl + 'tw/' + selectedCat + '/forms/' + selectedService + '/send-form.cfm';
//      var ajaxURL = globalUrl + 'tw/' + selectedCat + '/forms/includes/send-form-dev.cfm';


    /**
     * Store customer data as local storage
     */
    // Convert form data to object.
    var formObj = objectifyForm($form.serializeArray());

    // Localstore config
    var formDataLocal = [
        'c__first_name',
        'c__last_name',
        'c__phone',
        'c__email',
        'c__personal_number',
        'c__delivery_address__address',
        'c__delivery_address__postal_code',
        'c__delivery_address__port_code',
        'c__delivery_address__city',
        'c__invoice_address__address',
        'c__invoice_address__postal_code',
        'c__invoice_address__city'
    ];

    // Store in localstorage
    $.each( formDataLocal, function( key, value ) {
      if(formObj[value] != ''){
          localStorage.setItem(value, formObj[value]);
      }
    });



      // Ajax call
    $.ajax({
      url : ajaxURL,
      type: "POST",
      data :{dataStr:dataStr},
      dataType: 'json',
      success: function(resp, textStatus, jqXHR) {
        // Track step
        gatrack.trackStep($('.lc-booking-form-container').data('service'),'booked');
        $('.LC_wait').removeClass('show');
        $('.LC_response').addClass('show');
        $('.response').html(resp[0]['r']);

        // Set timeout to show upsales.
        setTimeout(function(){
          upsalesModal(formObj.f__handler);
        }, 5000);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // error handling
        // // console.log('error send');
        //showAlertError();
      }
    });
  }

  // -----------------------------------------
  // Action on groupBtn
  // -----------------------------------------
  $(document).on('click','.action-group', function(e) {
    
    e.stopImmediatePropagation();

    // Get the group ID to open
    var $group = $(this).attr('data-id');
    var $this = $(this);

    $('.action-group').removeClass('open-group');

  
    $('.LC_group').each(function() {

      if ($(this).attr('id') == $group) {

        if ($(this).hasClass('open')) {
          $(this).removeClass('open');
          //$this.removeClass('open-group');
        } else {
          $(this).addClass('open');
          //$this.addClass('open-group');
        }
      } else {
        
        $(this).removeClass('open');
        //$this.removeClass('open-group');
      }
    });


    if ($('#' + $group).hasClass('open')) {
      $this.addClass('open-group');
    }

  });


  // -----------------------------------------
  // Action on editBtn
  // -----------------------------------------
  $(document).on('click','.editBtn', function(e) {

    // console.log('click edit btn');

    e.stopImmediatePropagation();
    if ($(this).closest('.LC_resume_sub').length > 0) {
      var selectedSubStepID = $(this).closest('.LC_resume_sub').attr('id');
      var currentStepArr = getStepNr(selectedSubStepID);
      setStepToEdit(currentStepArr[0],currentStepArr[1]);
    } else {
      var selectedStepID = $(this).closest('.LC_step').attr('id');
      var currentStepArr = getStepNr(selectedStepID);
      setStepToEdit(currentStepArr[0],currentStepArr[1]);
    }

    if ($(this).hasClass('group-open')) {
      var $group = $(this).data('id');
      $('#'+$group).addClass('open');
    } else {
      $('.LC_group').removeClass('open');
    }

    // Get selected step ID and number
    // var selectedStepID = $(this).closest('.LC_step').attr('id');
    //var currentStepArr = getStepNr(selectedStepID);
    //// console.log('currentStepArr: ' + currentStepArr[0] + ' - ' + currentStepArr[1])
    /*
    var selectedStepNr = parseInt(selectedStepID.replace(/\D+/g, ''));
    // Get selected substep ID and number
    var selectedSubStepNr = null;
    if($(this).closest('.LC_resume_sub').length > 0) {
      var selectedSubStepID = $(this).closest('.LC_resume_sub').attr('id');
      selectedSubStepID = selectedSubStepID.replace(selectedStepID,'');
      selectedSubStepNr = parseInt(selectedSubStepID.replace(/\D+/g, ''));
    }
    setStepToActive(selectedStepNr,selectedSubStepNr);
    */
  });

  /*
  $(document).on('click','.nextBtn1, .submitBtn1', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    if ($form.valid()) {
      dataStep = $(this).data('step');
      // console.log('dataStep: ' + dataStep);
      if ($this.addClass('nextBtn')) {
        $this.addClass('selected');
        //dataStep = $(this).data('step');
        //dataStepTyp = $(this).data('steptyp');
        //actionStepTyp($(this),dataStepTyp,dataStep);
        
      }
      if ($this.addClass('submitBtn')) {
        dataStep = $this.data('step');
        dataStepTyp = $this.data('steptyp');
        actionStepTyp($this);
      }
      var nextStep = getNextStep();
      // console.log('Next step: ' + nextStep[0] + ' - ' + nextStep[1]);
      setStepToActive(nextStep[0],nextStep[1]);
    }
  });
  */
  /*
  // Action on editBtn
  $(document).on('click','.editBtn1', function(e) {
    e.stopImmediatePropagation();
    // Get selected step ID and number
    var selectedStepID = $(this).closest('.LC_step').attr('id');
    var selectedStepNr = parseInt(selectedStepID.replace(/\D+/g, ''));
    // Get selected substep ID and number
    var selectedSubStepNr = null;
    if($(this).closest('.LC_resume_sub').length > 0) {
      var selectedSubStepID = $(this).closest('.LC_resume_sub').attr('id');
      selectedSubStepID = selectedSubStepID.replace(selectedStepID,'');
      selectedSubStepNr = parseInt(selectedSubStepID.replace(/\D+/g, ''));
    }
    setStepToActive(selectedStepNr,selectedSubStepNr);
  });
  */


  // Upsales modal
  function upsalesModal(serviceHandler) {
      // TODO: change the static url. - should get from siteurl or global.
      // Populate modal with upsales offering based on order.
      var baseServiceUrl = '/for-privatpersoner/';

      var offeringsForOrderedService = {
          hemstadning: 'storstadning',
          storstadning: 'hemstadning',
          fonsterputs: 'hemstadning',
          flyttstadning: 'visningsstadning',
          visningsstadning: 'hemstadning',
          byggstadning: 'visningsstadning',
          mobeltvatt: 'hemstadning',
          badrumstvatt: 'fonsterputs',
          luktsanering: 'hemstadning',
          mattvatt: 'hemstadning',
          kokstvatt: 'hemstadning',
          stenpolering: 'hemstadning'
      };

      // Select service to promote based on order
      var upsalesSelected = offeringsForOrderedService[serviceHandler];

      // Show modal.
      $('.upsalesmodal').modal('show');

      // Change select to default.
      $('#f__upsales').val(upsalesSelected);
      $('#f__upsales').selectpicker('render');

      $('select#f__upsales').on('change', function(){
          upsalesSelected = this.value;
      });

      $('#upsales-book').on('click', function(){
          console.log(upsalesSelected);
          window.location.href = baseServiceUrl + upsalesSelected;
      });
  }


  function objectifyForm(formArray) {//serialize data function

      var returnArray = {};
      for (var i = 0; i < formArray.length; i++){
          returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
  }

}
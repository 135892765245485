var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;

if ($('.lc-booking-form-container').length) {

  window.calculatePriceTime = function($end) {

    if ($end == null) $end = 0;

    var priceValues = [];
    var timeValues = [];
    var withoutRutValues = [];

    $('[name$="_service_id"]').each(function(index){
      if ($(this).attr('name') != 'w__service_id') {
        
        // Get the element nb from 1..99
        if ($(this).attr('name').substring(14, 15) == '_') {
           var element_nb = $(this).attr('name').substring(13, 14); // get the nb of element
        } else {
          var element_nb = $(this).attr('name').substring(13, 15); // get the nb of element
        }
        
        // Get several variables for the element
        element_price = $('#w__sub__child' + element_nb + '_price').val(); // get the price of element
        element_rut = $('#w__sub__child' + element_nb + '_rut').val(); // get the price of element with no rut
        element_quantity = $('#w__sub__child' + element_nb + '_quantity').val(); // get the quantity of element
        element_time = $('#w__sub__child' + element_nb + '_time').val(); // get the time of element
        element_unit = $('#w__sub__child' + element_nb + '_unit').val(); // get the unit of element



        // Init variables for the element price, time and price without rut 
        element_priceTotal = 0;
        element_timeTotal = 0;
        element_withoutRut = 0;
        
        // Populate the price, time and ev. no rut price if the element have been selected, meaning 
        // the element has some quantity
        if (element_quantity != 0) {
          switch (element_unit) {
            case 'kr/tim': 
              // Get the current total time
              current_timeTotal = (round($('#f_serviceTime').val(),2) + round($('#f_childrenTime').val(),2))/60;
              // Set the total time into the element as quantity
              $('#w__sub__child' + element_nb + '_quantity').val(current_timeTotal);
              // Calculate the total price for the element kr/tim
              element_priceTotal = element_price * current_timeTotal;
              break;
            default:
              // Calculate the total price for the element
              element_priceTotal =  element_price * element_quantity;
          }
          // Calculate the total time for the element
          element_timeTotal = element_quantity * element_time;
          // Calculate the price without RUT if necessary
          element_withoutRut = 0;
          if (element_rut == 0) element_withoutRut = element_priceTotal; 
        } 

        // Populate the arrays for price, time and withoutRutValues
        priceValues[$(this).attr('name')] = element_priceTotal;
        timeValues[$(this).attr('name')] = element_timeTotal;
        if (element_withoutRut!=0) withoutRutValues[$(this).attr('name')] = element_withoutRut;
      }
    });

    // Calculate the total variable for each array 
    priceTotal = 0;
    for(var i in priceValues) priceTotal += priceValues[i];
    $('#f_childrenPrice').val(priceTotal);

    timeTotal = 0;
    for(var i in timeValues) timeTotal += timeValues[i];
    $('#f_childrenTime').val(timeTotal);

    withoutRutTotal = 0;
    for(var i in withoutRutValues) withoutRutTotal += withoutRutValues[i];
    $('#f_withoutRutPrice').val(withoutRutTotal);

    if ($('#f_calc').length) {
      switch($('#f_calc').val()) {
        case '1':
          showPriceTyp1(1,$end);
        case '2':
          showPriceTyp2(1,$end);
        case '3':
          showPriceTyp2(1,$end);
        case '4':
          showPriceTyp4(1,$end);
          break;
        case 'SP':
          showPriceTypSP(1,$end);
          break;
        case 'h1':
          showPriceTypH1(1,$end);
      }
    } else {
      showPrice(1,$end);
    }

  }

  window.showPriceTypH1 = function(nb,$end) {

    // ------------------------------
    // Get price info to calculate the end price
    // ------------------------------
    servicePrice = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    withoutRutPrice = round($('#f_withoutRutPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);
    if ($('#f_childrenPriceInit').length) childrenPriceInit = round($('#f_childrenPriceInit').val(),2);
    else childrenPriceInit = 0;

    // ------------------------------
    // Calculate the price master
    // ------------------------------
    servicePrice = calculatePrice(servicePrice,serviceRut,discountPercent);
    childrenPrice = calculatePrice(childrenPrice-withoutRutPrice,serviceRut,0);
    noRutPrice = calculatePrice(withoutRutPrice,0,0);

    priceMaster =  servicePrice + childrenPrice;

    TotalPrice = round(servicePrice + childrenPrice + noRutPrice,2);

    console.log('TotalPrice: ' + TotalPrice);
    console.log('noRutPrice: ' + withoutRutPrice + ' - ' + noRutPrice);

    // Set the text after the price
    serviceRutTxt = '';
    if (serviceRut == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 1 && $end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      // console.log('showPrice')
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
    }
  }

  window.showPrice = function(nb,$end) {
    
    // // console.log('showPrice');
    // Get price for current service
    servicePrice = servicePriceOrg = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    withoutRutPrice = round(($('#f_withoutRutPrice').val()/2),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    if ($('#f_childrenPriceInit').length) childrenPriceInit = round($('#f_childrenPriceInit').val(),2);
    else childrenPriceInit = 0;

    servicePrice = calculatePrice(servicePrice+childrenPriceInit,serviceRut,discountPercent);
    childrenPrice = calculatePrice(childrenPrice-childrenPriceInit,1,0);
    noRutPrice = calculatePrice(withoutRutPrice,0,0);
    priceMin = calculatePrice(priceMin,serviceRut,discountPercent);

    priceMaster = childrenPrice + servicePrice;

    //if ((childrenPrice + servicePriceOrg) <= priceMin) priceMaster = priceMin;
    if (priceMaster <= priceMin) priceMaster = priceMin;
    TotalPrice = round(priceMaster + noRutPrice,2);

    console.log(TotalPrice);

    // Set the text after the price
    serviceRutTxt = '';
    if (serviceRut == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 1 && $end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris ink moms)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      // console.log('showPrice')
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }
  }


  window.showPriceRut = function(nb,$end) {
    
    // Get price for current service
    servicePrice = servicePriceOrg = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    withoutRutPrice = round(($('#f_withoutRutPrice').val()/2),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    if ($('#f_childrenPriceInit').length) childrenPriceInit = round($('#f_childrenPriceInit').val(),2);
    else childrenPriceInit = 0;

    servicePrice = calculatePrice(servicePrice+childrenPriceInit,serviceRut,discountPercent);
    childrenPrice = calculatePrice(childrenPrice-childrenPriceInit,1,0);
    noRutPrice = calculatePrice(withoutRutPrice,0,0);
    priceMin = calculatePrice(priceMin,serviceRut,discountPercent);

    priceMaster = childrenPrice + servicePrice;
    if (priceMaster <= priceMin) priceMaster = priceMin;
    TotalPrice = round(priceMaster + noRutPrice,2);

    // // console.log('TotalPrice Rut -> ' + TotalPrice);

    // Set the text after the price
    serviceRutTxt = '';
    if (nb == 0) {
      TotalPrice = round(TotalPrice*2,2);
      serviceRutTxt = '(Pris ink moms)';
      if ($end == 1) serviceRutTxt = '(Pris ink moms)';
    } else {
      serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
      if ($end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    } 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }

  }

  // Method luktsanering
  window.showPriceTyp1 = function(nb,$end) {
    
    // Get price for current service
    servicePrice = round($('#f_servicePrice').val(),2);
    child1Qt = round($('#w__sub__child1_quantity').val(),2);
    child1Price = round($('#w__sub__child1_price').val(),2);
    child1Price = child1Price * child1Qt;
    childrenPrice = round($('#f_childrenPrice').val(),2);
    withoutRutPrice = round(($('#f_withoutRutPrice').val()/2),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    servicePrice = calculatePrice((servicePrice+child1Price),serviceRut,discountPercent);
    childrenPrice = calculatePrice(childrenPrice,1,0);
    noRutPrice = calculatePrice(withoutRutPrice,0,0);
    priceMin = calculatePrice(priceMin,1,0);

    priceMaster = servicePrice;
    if (servicePrice <= priceMin) {
      priceMaster = calculatePrice(priceMin,serviceRut,discountPercent);
      TotalPrice = round(priceMaster*2/1.25,2);
    } else {
      TotalPrice = round(priceMaster,2);
    }

    // Set the text after the price
    serviceRutTxt = '';
    if (serviceRut == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 1 && $end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris ink moms)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }

  }

  // Method Mattvätt
  window.showPriceTyp2 = function(nb,$end) {
    
    // Get price for current service
    servicePrice = round($('#f_servicePrice').val(),2);
    child1Qt = round($('#w__sub__child1_quantity').val(),2);
    child1Price = round($('#w__sub__child1_price').val(),2);
    child1Price = child1Price * child1Qt;
    childrenPrice = round($('#f_childrenPrice').val(),2);
    withoutRutPrice = round(($('#f_withoutRutPrice').val()/2),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    // console.log('price -> ' + (servicePrice+child1Price+childrenPrice));

    priceMin = calculatePrice(priceMin,serviceRut,discountPercent);
    servicePrice = calculatePrice((servicePrice+child1Price),serviceRut,discountPercent);
    childrenPrice = calculatePrice((childrenPrice-child1Price),0,0);

    if ((servicePrice+childrenPrice) < priceMin) {
      TotalPrice = round(priceMin,2);
    } else {
      TotalPrice = round((servicePrice + childrenPrice),2);
    }
    
    // Set the text after the price
    serviceRutTxt = '';
    if (serviceRut == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 1 && $end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris ink moms)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }

  }

  window.showPriceTyp4 = function(nb,$end) {
    
    // Get price for current service
    servicePrice = servicePriceOrg = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    childrenPriceIni = round($('#f_childrenPriceInit').val(),2);
    //  withoutRutPrice = round(0.4*($('#f_withoutRutPrice').val()/2),2); 
    withoutRutPrice = round($('#f_withoutRutPrice').val(),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    // Get the Material initial cost
    if ($('#f_childrenPriceInit').length) {
      materialInitCost = calculatePrice(childrenPriceIni,0,0);
    } else {
      materialInitCost = 0;
    }
    // console.log('materialInitCost > ' + materialInitCost)
    
    // Set the service Price with ROT calculation
    if (nb == 0) servicePrice = Math.round(2*calculatePrice(servicePrice,serviceRut,discountPercent),2);
    else servicePrice = Math.round(1.4*calculatePrice(servicePrice,serviceRut,discountPercent),2);
    // console.log('servicePrice > ' + servicePrice)

    // Children Price with ROT calculation
    childrenPrice = childrenPrice-withoutRutPrice;
    if (nb == 0) childrenPrice = Math.round(2*calculatePrice(childrenPrice,serviceRut,0),2)
    else childrenPrice = Math.round(1.4*calculatePrice(childrenPrice,serviceRut,0),2);
    // console.log('childrenPrice > ' + childrenPrice)

    // Extra Material cost
    if (withoutRutPrice > childrenPriceIni) {
      materialExtraCost = withoutRutPrice - childrenPriceIni;
      materialExtraCost = Math.round(calculatePrice(materialExtraCost,0,0),2);
    } else {
      materialExtraCost = 0;
    }
    // console.log('materialExtraCost > ' + materialExtraCost)


    TotalPrice = Math.round(materialInitCost + servicePrice + childrenPrice + materialExtraCost,2);
    // console.log('TotalPrice > ' + TotalPrice)

    // Set the text after the price
    serviceRutTxt = '';
    if (nb == 0) serviceRut = 0;
    if (serviceRut == 1) serviceRutTxt = '(Pris ink moms efter ROT-avdrag)';
    if (serviceRut == 1 && $end == 1) serviceRutTxt = '(Pris ink moms efter ROT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris ink moms)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      // console.log('showPrice')
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }
  }

    window.showPriceTypSP = function(nb,$end) {
    
    // Get price for current service
    servicePrice = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    childrenPriceIni = round($('#f_childrenPriceInit').val(),2);
    withoutRutPrice = round($('#f_withoutRutPrice').val(),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    //serviceRut = round($('#f_rut').val(),2);
    serviceRut = 2; // ROT deduction
    discountPercent = round($('#f_discountPercent').val(),2);

    // Get the service price with ROT calculation
    servicePrice = Math.round(calculatePrice(servicePrice,serviceRut,discountPercent),2);

    // Get the Material initial cost
    if ($('#f_childrenPriceInit').length) materialInitCost = calculatePrice(childrenPriceIni,0,0);
    else materialInitCost = 0;

    // Children Price with ROT calculation
    childrenPrice = childrenPrice-withoutRutPrice;
    childrenPrice = Math.round(calculatePrice(childrenPrice,serviceRut,0),2);

    // Extra Material cost
    if (withoutRutPrice > childrenPriceIni) {
      materialExtraCost = withoutRutPrice - childrenPriceIni;
      materialExtraCost = Math.round(calculatePrice(materialExtraCost,0,0),2);
    } else {
      materialExtraCost = 0;
    }

    TotalPrice = Math.round(materialInitCost + servicePrice + childrenPrice + materialExtraCost,2);

    // Set the text after the price
    serviceRutTxt = '';
    if (nb == 0) serviceRut = 0;
    if (serviceRut == 2) serviceRutTxt = '(Pris ink moms efter ROT-avdrag)';
    if (serviceRut == 2 && $end == 1) serviceRutTxt = '(Pris ink moms efter ROT-avdrag)';
    if (serviceRut == 0) serviceRutTxt = '(Pris ink moms)';
    if (serviceRut == 0 && $end == 1) serviceRutTxt = '(Pris ink moms)'; 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      // console.log('showPrice')
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }
  }

  window.showPriceRot = function(nb,$end) {
    
    // Get price for current service
    servicePrice = servicePriceOrg = round($('#f_servicePrice').val(),2);
    childrenPrice = round($('#f_childrenPrice').val(),2);
    //  withoutRutPrice = round(0.4*($('#f_withoutRutPrice').val()/2),2); 
    withoutRutPrice = round($('#f_withoutRutPrice').val(),2); 
    priceMin = round($('#f_serviceMinPrice').val(),2);
    serviceRut = round($('#f_rut').val(),2);
    discountPercent = round($('#f_discountPercent').val(),2);

    // Get the childrenPriceInit
    if ($('#f_childrenPriceInit').length) childrenPriceInit = round($('#f_childrenPriceInit').val(),2);
    else childrenPriceInit = 0;
    childrenPriceInitOrg = childrenPriceInit;
    childrenPriceInit = calculatePrice(childrenPriceInit,0,0);
    // console.log('childrenPriceInit > ' + childrenPriceInit)

    // Set the service Price with ROT calculation
    if (nb == 0) servicePrice = Math.round(1.4*calculatePrice(servicePrice,serviceRut,discountPercent)/0.7,2)
    else servicePrice = Math.round(1.4*calculatePrice(servicePrice,serviceRut,discountPercent),2);
    // console.log('servicePrice > ' + servicePrice)

    // Children Price with ROT calculation
    childrenPrice = childrenPrice-withoutRutPrice;
    if (nb == 0) childrenPrice = Math.round(1.4*calculatePrice(childrenPrice,serviceRut,0)/0.7,2)
    else childrenPrice = Math.round(1.4*calculatePrice(childrenPrice,serviceRut,0),2);
    // console.log('childrenPrice > ' + childrenPrice);

    // Extra Material cost
    materialExtraCost = withoutRutPrice - childrenPriceInitOrg;
    materialExtraCost = Math.round(calculatePrice(materialExtraCost,0,0),2);
    // console.log('materialExtraCost > ' + materialExtraCost)
    
    TotalPrice = Math.round(childrenPriceInit + servicePrice + childrenPrice + materialExtraCost,2);

    // Set the text after the price
    serviceRutTxt = '';
    if (nb == 0) {
      TotalPrice = round(TotalPrice*10/7,2);
      serviceRutTxt = '(Pris ink moms)';
      if ($end == 1) serviceRutTxt = '(Pris ink moms)';
    } else {
      serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
      if ($end == 1) serviceRutTxt = '(Pris ink moms efter RUT-avdrag)';
    } 
    
    // Write the price in the footer
    if (isNaN(TotalPrice)) $('#LC_footer .price').html(' - ');
    else {
      $('#LC_footer .price').html(Math.round(TotalPrice,2) + ' sek <span class="rutTxt">' + serviceRutTxt + '</span>');
      $('#pt').val(Math.round(TotalPrice,2) + ' sek ' + serviceRutTxt);
    }

  }
}
//# sourceURL=block-module-46-booking-price.js

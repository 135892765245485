var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;

if ($('.lc-inquiry-form-container').length) {

    /**
     * Global validation functions for all FTG + FST forms.
     * Everything that is always the same on both forms, mainly contact validation.
     */
    // Validator function for personalnumber
    window.validatePersonalNumber = function (input) {
        // Check valid length & form
        if (!input) return false;
        if (input.indexOf('-') == -1) return false;
        if (input.indexOf('-') == -1) {
            if (input.length === 10) {
                input = input.slice(0, 6) + "-" + input.slice(6);
            } else {
                input = input.slice(0, 8) + "-" + input.slice(8);
            }
        }
        if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) return false;
        // Clean input
        input = input.replace('-', '');
        if (input.length == 12) {
            input = input.substring(2);
        }

        // Declare variables
        var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
            sum = 0,
            numdigits = input.length,
            parity = numdigits % 2,
            i,
            digit;
        // Check valid date
        if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;
        // Check luhn algorithm
        for (i = 0; i < numdigits; i = i + 1) {
            digit = parseInt(input.charAt(i))
            if (i % 2 == parity) digit *= 2;
            if (digit > 9) digit -= 9;
            sum += digit;
        }
        return (sum % 10) == 0;
    };


    /**
     * Global validation rules for all FTG + FST forms.
     * Everything that is always the same on both forms, mainly contact validation.
     */
    // Validator get error messages
    function getFormMsg(selector) {
        return $(selector).attr('data-msg');
    }

    // Validator personalnumber
    $.validator.addMethod("personalNumber", function (value, element) {
        return window.validatePersonalNumber(value);
    }, "wrong");

    // Validator for email.
    $.validator.addMethod("validateEmail", function (value, element) {
        if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
            return true;
        } else {
            return false;
        }
    }, "Please enter a valid Email.");

    // Validator checkbox.
    $.validator.addMethod('oneCheckbox', function (value, ele) {
        // console.log('ele checked: ' + $(ele).is(':checked'));
        return $(ele).is(':checked');
    }, "false");


    // Add required for css class "forcevalidation"
    jQuery.validator.addClassRules({
        forcevalidation: {
            required: true
//            maxlength: 10
        }
    })

    // Main vaildation settings, mainly for contact form.
    window.validatorFormContainer = function () {
        $('.lc-inquiry-form-container form').validate({
            onkeyup: function (element, event) {
            },
            onfocusout: function (element) {
                this.element(element);
            },
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code: {
                    required: true, digits: true, maxlength: 5, minlength: 5,
                    remote: {
                        url: globalUrl + 'tw/pri/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                c__last_name: {
                    required: true
                },
                c__personal_number: {
                    required: true, personalNumber: true
                },
                c__phone: {
                    required: true
                },
                c__email: {
                    required: true, validateEmail: true
                },
                c__delivery_address__address: {
                    required: true
                },
                c__delivery_address__city: {
                    required: true
                },
                servicePolishQt: {
                    required: true
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
            },
            errorContainer: "#LC_error",
            errorLabelContainer: "#LC_error alert",
            errorPlacement: function (error, element) {
                // console.log(error)
                $('.LC_alert').show();
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html': true, 'trigger': 'hover focus'});
                }

            }
        });


        /**
         * Global validation / helper events - onchanges, keyups and such things.
         */
        // Personalnumber (orgnr) force '-'
        $('#c__personal_number').keyup(function () {
            $this = $(this);
            if ($this.val().length >= 6) {
                if ($this.val().charAt(6) != '-') {
                    newVal = [$this.val().slice(0, 6), '-', $this.val().slice(8)].join('');
                    $this.val(newVal);
                }
            }
        });

    }



}


/**
 * Slider validations below.
 */

// TODO: Not sure if this should be enabled in the module-47.
// TODO: Rules for this should be imported from each area (some logic in 46, some logic in 47. Would be the best.

/**
if ($('.lc-booking-slider-container').length) {

    // -----------------------------------------
    // Jquery Validation Slider Pri
    // -----------------------------------------
    window.validatorFormPriSlider = function () {
        $('.lc-booking-form-pri-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code: {
                    required: true, digits: true, maxlength: 5, minlength: 5,
                    remote: {
                        url: globalUrl + 'tw/pri/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required: true, digits: true, maxlength: 4, minlength: 1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function (error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html': true, 'trigger': 'hover focus'});
                }
            }
        });
    }


    // -----------------------------------------
    // Jquery Validation Slider fst
    // -----------------------------------------
    window.validatorFormFstSlider = function () {
        $('.lc-booking-form-fst-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code: {
                    required: true, digits: true, maxlength: 5, minlength: 5,
                    remote: {
                        url: globalUrl + 'tw/fst/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required: true, digits: true, maxlength: 4, minlength: 1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function (error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html': true, 'trigger': 'hover focus'});
                }
            }
        });
    }

    // -----------------------------------------
    // Jquery Validation Slider fst
    // -----------------------------------------
    window.validatorFormFtgSlider = function () {
        $('.lc-booking-form-ftg-slider form').validate({
            onclick: false,
            ignore: ":hidden",
            rules: {
                c__delivery_address__postal_code: {
                    required: true, digits: true, maxlength: 5, minlength: 5,
                    remote: {
                        url: globalUrl + 'tw/ftg/forms/includes/check-zip-code.cfm',
                        data: {f__handler: $('#f__handler').val()},
                        type: 'post',
                        async: false,
                        onkeyup: false
                    }
                },
                f_quantity: {
                    required: true, digits: true, maxlength: 4, minlength: 1
                }
            },
            highlight: function (element) {
                $(element).closest('.field').removeClass('has-success').addClass('has-danger');
            },
            unhighlight: function (element) {
                $(element).closest('.field').removeClass('has-danger').addClass('has-success');
                $(element).tooltip('dispose');
            },
            errorPlacement: function (error, element) {
                msg = getFormMsg(element);
                if (msg) {
                    $(element).attr('title', msg);
                    $(element).tooltip({'html': true, 'trigger': 'hover focus'});
                }
            }
        });
    }

}  
*/

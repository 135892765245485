var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;



if ($('.lc-booking-form-container').length) {

  // -----------------------------
  // Checkbox Show/hide field
  // -----------------------------
  $(document).on('change','.chkbox-form-show', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    // Get the data-target
    var eltTarget = $this.attr('data-target');
    var $eltTarget = $('#' + eltTarget + '');
    // Show/Hide
    //$eltTarget.toggle();
    $eltTarget.closest('.form-group').toggle(); 
    if (!$this.attr('checked')) {
      $eltTarget.val('');
      $eltTarget.closest('.field').removeClass('has-success');
    }
  });

  

  window.iniCheckbox = function() {
    $('.chkbox-form-show').each(function(index){
      var $this = $(this);
      // Get the data-target
      var eltTarget = $this.attr('data-target');
      var $eltTarget = $('#' + eltTarget + '');
      // Check if the element value is in the data-target array
      if ($eltTarget.val() != '') {
        $this.prop('checked',true);
        $eltTarget.closest('.form-group').show();
      } else {
        $this.prop('checked',false);
        //$eltTarget.hide();
        $eltTarget.closest('.form-group').hide();  
      }
    });
  };

  // -----------------------------
  // Checkbox show/hide group
  // -----------------------------
  $(document).on('change','.chkbox-form-group', function(e) {
    
    e.stopImmediatePropagation();
    
    var $this = $(this);

    // Get the data-target
    var eltTarget = $this.attr('data-target');
    var $eltTarget = $('#' + eltTarget + '');

    // Show/Hide
    $eltTarget.toggle();
    //if (!$this.attr('checked')) $eltTarget.val('');
    if (!$this.attr('checked')) {
      $eltTarget.find('input').each(function(index) {
        $(this).val('');
        $(this).closest('.field').removeClass('has-success');
        $(this).closest('.field').find('label').removeClass('label-show');
      });
    }
      
  });

  window.iniCheckboxGroup = function() {
    
    $('.chkbox-form-group').each(function(index) {
      
      var $this = $(this); 

      var eltTarget = $this.attr('data-target');
      var $eltTarget = $('#' + eltTarget + '');

      var eltStatus = 'off';
      $eltTarget.find('input').each(function(index) {
        if ( $(this).val() != '') {
          eltStatus = 'on';
          $(this).closest('.field').addClass('has-success');
        }
      });

      if (eltStatus == 'on') {
        $this.prop('checked',true);
        $eltTarget.show();
      } else {
        $this.prop('checked',false);
        $eltTarget.hide();
      }

    });
  
  }

  // -----------------------------
  // Checkbox Price
  // -----------------------------
  window.iniCheckboxPrice = function() {

    // Initialisation
    $('.chkbox-form-price:not(.window)').each(function(index){
      var $this = $(this); 
      // get the element Quantity
      var eltQuantity = $this.attr('data-quantity');
      var $eltQuantity = $('#' + eltQuantity + '');
      // Adjust eventually the quantity

      if ($this.hasClass('invert')) {
        if ($eltQuantity.val() == 0) $this.prop('checked',true);
        else $this.prop('checked',false);
      } else {
        if ($eltQuantity.val() > 0) $this.prop('checked',true);
        else $this.prop('checked',false);
      } 
    });
  }

  $(document).on('change','.chkbox-form-price:not(.window)', function(e) {

    // console.log('change chkbox-form-price:not(.window)');

    var $this = $(this);    
    // Get the elt ID
    var eltID = $this.attr('data-id');
    var $eltID = $('#' + eltID + ''); 
    // Get the Rut
    var eltRut = $this.attr('data-rut');
    var $eltRut = $('#' + eltRut + '');    
    // Get the quantity
    var eltQuantity = $this.attr('data-quantity');
    var $eltQuantity = $('#' + eltQuantity + '');    
    // Get the price
    var eltPrice = $this.attr('data-price');
    var $eltPrice = $('#' + eltPrice + '');
    var eltPriceVal = $eltPrice.val();      
    // Get the time
    var eltTime = $this.attr('data-time');
    var $eltTime = $('#' + eltTime + '');
    var eltTimeVal = $eltTime.val();      
    // Set the element checked to on or empty
    // and adjust the quantity

    if ($this.hasClass('dynamicQt')) {
      if ($this.is(':checked')) $eltQuantity.val($('#f_quantity').val());
      else $eltQuantity.val('0');
    } else {
      if ($this.hasClass('invert')) {
        if ($this.is(':checked')) $eltQuantity.val('0');
        else $eltQuantity.val('1');
      } else {
        if ($this.is(':checked')) $eltQuantity.val('1');
        else $eltQuantity.val('0');
      }
    }
    // Calculate price & time    
    calculatePriceTime();

  });

  // -----------------------------
  // Checkbox Price Window
  // -----------------------------
  window.iniCheckboxPriceWindow = function() {

    // Initialisation
    $('.chkbox-form-price.window').each(function(index){
      var $this = $(this); 
      // get the element Quantity
      var eltQuantity = $this.attr('data-quantity');
      var $eltQuantity = $('#' + eltQuantity + '');
      // Adjust eventually the quantity
      if ($this.hasClass('invert')) {
        if ($eltQuantity.val() == 0) $this.prop('checked',true);
        else $this.prop('checked',false);
      } else {
        if ($eltQuantity.val() > 0) $this.prop('checked',true);
        else $this.prop('checked',false);
      }
    });

  }

  
  $(document).on('change','.chkbox-form-price.window', function(e) {

     // console.log('change chkbox-form-price.window)');

    var $this = $(this); 

    // Get the element ID
    var eltID = $this.data('id');
    var $eltID = $('#' + eltID);
   
    // Get the element quantity ID
    var eltQuantity = $this.data('quantity');
    var $eltQuantity = $('#' + eltQuantity);

    // Set the element to checked if necessary and adjust the quantity
    if ($this.is(':checked')) {
      $eltQuantity.val(windowGetCount());
      // Update the resume
      $('#' + eltID +'_resume').addClass('show').parent().removeClass('hidden-xs-up');
    } else {
      $eltQuantity.val(0);
      // Update the resume
      $('#' + eltID +'_resume').removeClass('show').parent().addClass('hidden-xs-up');
    }
    

    // Calculate price & time    
    calculatePriceTime();

  });

  window.updateResumeCheckboxBtn = function($this,val) {

    var $resumeID = $('#' + $this);

    if (val == 0) {
      $resumeID.removeClass('show').parent().addClass('hidden-xs-up');
    } else {
      $resumeID.addClass('show').parent().removeClass('hidden-xs-up');;
      $resumeID.find('.number').html(val);
    }

    // Get LC_step
    var $stepToCheck = $resumeID.closest('.LC_step').attr('id');

    if (checkResumeFormGroup($stepToCheck) == true) {
      $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().removeClass('hidden-xs-up');
    } else {
      $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().addClass('hidden-xs-up');
    }
     
    // Check if all div in resume are hidden
    /*showFormGroupIni = true; nbFormGroup = 0; nbFormGroupHidden = 0;
    $('.LC_resume').find('.resumeFormGroup').each(function( index ) {
      if (!$(this).hasClass('ini')) {
        nbFormGroup++;
        if ($(this).parent().hasClass('hidden-xs-up')) nbFormGroupHidden++;
      }
    });
    // console.log('nbFormGroup: ' + nbFormGroup);
    // console.log('nbFormGroupHidden: ' + nbFormGroupHidden);*/
    //if (showFormGroupIni == true) $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().removeClass('hidden-xs-up');
    //else $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().addClass('hidden-xs-up');
    
    
  }

  // -----------------------------
  // Checkbox comment
  // -----------------------------
  window.iniCheckboxComment = function() {
    
    $('.chkbox-form-comment').each(function(index){
      var $this = $(this); 
      var eltValue = $this.val().toString();
      // Get the elt id
      var eltTarget = $this.attr('data-id');
      var $eltTarget = $('#' + eltTarget + '');
      // Get the value of the target
      var eltTargetArray = $eltTarget.val().toString().split('|');
      // init the comment prop
      if ($this.hasClass('invert')) {
        if (eltTargetArray.indexOf(eltValue) > -1) $this.prop('checked',false);
        else $this.prop('checked',true);
      } else {
        if (eltTargetArray.indexOf(eltValue) > -1) $this.prop('checked',true);
        else $this.prop('checked',false);
      } 
    });

    $('.chkbox-form-comment1').each(function(index){
      var $this = $(this); 
      $this.prop('checked',false);
       
    });

  };

  $(document).on('change','.chkbox-form-comment', function(e) {
    var $this = $(this);
    // Get the target
    var eltTarget = $this.attr('data-id');
    var $eltTarget = $('#' + eltTarget + '');
    // Set the comment
    var commentVar = '';
    if ($this.hasClass('invert')) {
        $('input[data-id="'+eltTarget+'"]').not(':checked').each(function(e) {
          commentVar = commentVar + $(this).val() + '|';
        });
    } else {
        $('input[data-id="'+eltTarget+'"]:checked').each(function(e) {
          commentVar = commentVar + $(this).val() + '|';
        });
    } 
    commentVar = commentVar.substring(0, commentVar.length-1);
    // populate the comment 
    $eltTarget.val(commentVar);
  });

  $(document).on('change','.rut', function(e) {
    var $this = $(this); 
    var currentStep = getStepNr($this.closest('.LC_step').attr('id'))
    var nextStep = getNextStep(currentStep);
    var $end = 0;
    if (nextStep[0] == 0) $end = 1;
    if ($this.is(':checked')) {
      showPriceRut(1,$end);
    }
    else showPriceRut(0,$end);
  });

  $(document).on('change','.rot', function(e) {
    var $this = $(this); 
    var currentStep = getStepNr($this.closest('.LC_step').attr('id'))
    var nextStep = getNextStep(currentStep);
    var $end = 0;
    if (nextStep[0] == 0) $end = 1;
    if ($this.is(':checked')) {
      showPriceRot(1,$end);
    }
    else showPriceRot(0,$end);
  });

  $(document).on('change','.rot4', function(e) {
    var $this = $(this); 
    var currentStep = getStepNr($this.closest('.LC_step').attr('id'))
    var nextStep = getNextStep(currentStep);
    var $end = 0;
    if (nextStep[0] == 0) $end = 1;
    if ($this.is(':checked')) showPriceTyp4(1,$end);
    else showPriceTyp4(0,$end);
  });
  


}



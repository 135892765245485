var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;
var globalLoaderCode = globals.loaderCode;

if ($('.lc-inquiry-form-container').length) {

	// -----------------------------------------
	// Init loader with right HTML code
	// -----------------------------------------
	window.setLoaderHTML = function() {
		$('.LC_loader').each(function() {
			$(this).html(globalLoaderCode);
		});
	}

	// -----------------------------------------
	// Show LC Loader and hide what's needed
	// -----------------------------------------
	window.showLCLoader = function ($show, $hide) {
		if ($show != null) $show.addClass('show-loader');
		if ($hide != null) $hide.addClass('hide-loader');
	}

	// -----------------------------------------
	// Show LC Loader and hide what's needed
	// -----------------------------------------
	window.hideLCLoader = function () {
		$('.show-loader').removeClass('show-loader');
		$('.hide-loader').removeClass('hide-loader');
	}

	// -----------------------------------------
	// Show LC Alert
	// -----------------------------------------
	window.showLCAlert = function ($showAlert) {
		if ($showAlert != null) $showAlert.addClass('show-alert');
	}

	// -----------------------------------------
	// Show Top Error
	// -----------------------------------------
	window.showAlertError = function() {
		var $showAlert = $('.section-form-nobkg .LC_alert'); // alert to show
		showLCAlert($showAlert); // Show the alert
	}

	// -----------------------------------------
	// Init form in general
	// -----------------------------------------
	window.formInitGeneral = function() {
		
		// Hide the alert
		$('.LC_alert').hide(); 
		

		// Check if there are some input already filled
		// And show the label
		$('.input input').each(function() {
	  	checkForInput(this);
		});

		// When an input is changed, show the label
		$('.input input').on('change keyup', function() {
	  	checkForInput(this);  
		});

	    // TODO Might enable again if price checkbox logic is needed?

		// Init some types of checkbox fields
		iniCheckbox(); 
		iniCheckboxGroup();

		iniCheckboxPrice();

		iniCheckboxPriceWindow();

        /**
		iniCheckboxComment();

		*/
        /**
		// Init some types of radio button
		iniRadioComment();
		*/

		// set HTML code to All Loader
		setLoaderHTML();

	}

}


if ($('.lc-inquiry-form-container').length) {

	// -----------------------------------------
	// Fill each loader with right code
	// ----------------------------------------- 
	setLoaderHTML();

	// -----------------------------------------
	// Load selected form service into container
	// -----------------------------------------
	var serviceName = $('.lc-inquiry-form-container').data('service'); // Get service name
    var serviceCategoryName = 'pri';// Get service name, set pri as default

    if($('.lc-inquiry-form-container').data('cat')){
        serviceCategoryName  = $('.lc-inquiry-form-container').data('cat');
    }

	// TOOD: can't always load pri here doesn't work.
  var loadURL = globalUrl + '/tw/' + serviceCategoryName + '/forms/' + serviceName + '/form-steps.cfm'; // Set url
  $('.lc-inquiry-form-container').load(loadURL); // Load url into container

  // -----------------------------------------
	// Show or hide label for input
	// -----------------------------------------
  window.checkForInput = function(element) {
  
  	var $label = $(element).siblings('label');

  	if ($(element).val().length > 0) {
    	$label.addClass('label-show');
    	$(element).closest('.field').addClass('has-success');
  	} else {
    	$label.removeClass('label-show');
  	}

	}


	// -----------------------------------------
	// Extract step nr från ID
	// -----------------------------------------
	window.getStepNr = function(stepNrStr) {
		var str = stepNrStr;
		var arrStr = str.split(/_/g).slice(0);
		stepNr = arrStr[2];
		subStepNr = arrStr[4];
  	return [stepNr,subStepNr];
  	
  }


  // -----------------------------------------
	// check checkAjaxStatus
	// -----------------------------------------
	window.checkAjaxStatus = function() {
		if ($('.lc-inquiry-form-container').find('.ajx').length == 0) {
			$('.show-loader').removeClass('show-loader');
			$('.hide-loader').removeClass('hide-loader');
			$('#extraServices').removeClass('hide');
		}
  }

  

	window.loader = function() {
		if ($('.waitAjax').length == 0) {
			hideLoaders();
		}
	}

	window.round = function(value, decimals) {
		return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
	}

	window.setDataAttr = function($this,dataID,dataVar){
		//// console.log('window.setDataAttr');
	  dataAttr = dataID.replace('data-', '');
		$this.removeData(dataAttr);
		$this.attr(dataID,dataVar);
	};

}






// Bootstrap wants jQuery global
window.jQuery = $ = require('jquery');
window.validate = require('../js/jquery.validate.min.js');
window.validateAddMethod = require('../js/additional-methods.min.js');

window.printThis = require('../js/print-this.js');


// Bootstrap wants Tether global
window.Tether = require('../../node_modules/tether/dist/js/tether.js');

// We need underscores for calendar and simple templating.
window.underscore = _ = require('underscore');


// Load libraries
// Bootstrap;
var bootstrap = require('../../node_modules/bootstrap/dist/js/bootstrap.js');
var slickslider = require('../../node_modules/slick-carousel/slick/slick.js');
var sneakpeek = require('sneakpeek');
var datepicker = require('./bootstrap-datepicker.min.js');

window.aos = aos = require('aos');
var Cookies = require('js-cookie');
var bootstrapselect = require('../js/bootstrap-select-thdoan.js');
var moment = require('moment-timezone');
// Set default timezone for moment.
moment.tz.setDefault("Europe/Stockholm");

require('moment/locale/sv');
window.moment = moment;

// Polyfill for img cover on IE.
var objectFitImages = require('object-fit-images');

// Bootstrap calendar main library
var bscalendar = require('../js/bootstrap-calendar-fork.js');

// Globally load google analytics trackstep function.
window.gatrack = require('../js/gatrack.js');

// Globally load calendar function.
window.bookingCalendar = require('../js/bookingcalendar.js');

// underscore templates variables
window.utemplates = require('./underscoretemplates.js');

$(document).ready(function () {
    var globalVariables = require('../modules/global-variables/global-variables.js');

    // Set browser user agent in html to provide styling for IE10 - https://css-tricks.com/ie-10-specific-styles/
    var doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);

    objectFitImages();

    // Convert inline js, to not be render blocking.
    //   $('#somescript').replaceWith('<script>' + $('#somescript').html() + '</script>');

    var viewportWidth = $(window).width();

    /*
    var nativeSelect = false;
    if (viewportWidth > 768) {
        var rellaxpara = new rellax('.rellax');
        nativeSelect = true;
    }*/


    $('a[href="#"]').click(function(e) {
        e.preventDefault ? e.preventDefault() : e.returnValue = false;
    });



    // after each ajax is complete.
    $( document ).ajaxComplete(function() {

        initSelectPicker();


        // Expand conditions in form.
        $('.condition-expand').on( "click", function(e) {
          //  console.log('clcik condition')
            e.stopImmediatePropagation(); 
            if($('.conditions-container').hasClass('open')) {
                // If open -> close
                $('.conditions-container').removeClass('open');
            } else {
                // if closed -> open
                $('.conditions-container').addClass('open');
                scrollIntoViewIfNeeded($('.conditions-container'));
            }

            e.preventDefault();
            e.stopPropagation();
        });

    });


    initSelectPicker();

    function initSelectPicker(){

        // If selectpicker is in slider add data attribute for container.
        if ( $('.lc-selectpicker').parents('.slick-track').length ) {
            $('.lc-selectpicker').attr('data-container','.booking-slide');

            // Workaround for show problem.
            $( window ).scroll(function() {
                $('section.booking-slide').find('.btn-group').removeClass('show');
            });
        }



        // Select picker config
        $('.lc-selectpicker').selectpicker(
            {
                showIcon: true,
                dropupAuto: false
            }
        );

        // Select picker config
        $('.lc-selectpickerOFF').selectpicker(
            {
                noneSelectedText: 'Nothing selected',
                noneResultsText: 'No results matched {0}',
                countSelectedText: function (numSelected, numTotal) {
                    return (numSelected == 1) ? "{0} item selected" : "{0} items selected";
                },
                maxOptionsText: function (numAll, numGroup) {
                    return [
                        (numAll == 1) ? 'Limit reached ({n} item max)' : 'Limit reached ({n} items max)',
                        (numGroup == 1) ? 'Group limit reached ({n} item max)' : 'Group limit reached ({n} items max)'
                    ];
                },
                selectAllText: 'Select All',
                deselectAllText: 'Deselect All',
                doneButton: false,
                doneButtonText: 'Close',
                multipleSeparator: ', ',
                styleBase: 'btn',
                //     style: 'btn-select',
                size: 'auto',
                title: null,
                selectedTextFormat: 'values',
                width: false,
                container: false,
                hideDisabled: false,
                showSubtext: false,
                showIcon: true,
                dropupAuto: false,
                /*
                showContent: true,
                dropupAuto: true,
                header: false,
                liveSearch: false,
                liveSearchPlaceholder: null,
                liveSearchNormalize: false,
                liveSearchStyle: 'contains',
                actionsBox: false,
                iconBase: 'glyphicon',
                tickIcon: 'glyphicon-ok',
                showTick: false,
                template: {
                    caret: '<span class="caret"></span>'
                },
                maxOptions: false,
                mobile: nativeSelect,
                selectOnTab: false,
                dropdownAlignRight: false,
                windowPadding: 0

                */
            }
        );
    }


    // Init aos. (Animate on scroll)
    aos.init({
        offset: 50,
        delay: 0,
        duration: 850
    });

    // Hide with sneakpeek on scroll
    sneakpeek(document.getElementById("main-navigation"));

    // Hide any open dropdowns. And active hovers, when menu is hiding
    sneakpeek.emitter.on('hide', function () {
        closeDropdownFold();
    });

    // Hide any open dropdowns. And active hovers, when menu is hiding
    sneakpeek.emitter.on('show', function () {
      //  closeDropdownFold();
        // Work around for ipad double tap after menu is hidden, should just be single tap.
    //    $('a').css('cursor','pointer');
    });


    // Booking form slider JS - super simple.
    $('.bookingform-radio-switch a').on('click touchstart', function (e) {

        // Toogle active
        $(this).parent().find('a').removeClass('active');
        $(this).toggleClass('active');

        // Hide all
        $($(this).data("parent")).removeClass('slide-visible');

        // Show one
        $($(this).data("target")).addClass('slide-visible');
    });


    // Init booking slide
    $('.booking-slide').slick({
        arrows: false,
        autoplay: true,
//        speed: 1000,
        customPaging: function (slider, i) {
            return $('<button type="button" data-role="none" role="button" tabindex="0" />').text($('.booking-slide').find('div[data-slick-index=' + i + ']').data('title'));
        },

        fade: true,
        dots: true,

        infinite: true,
        autoplaySpeed: 70000,
        speed: 160,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $('.booking-slide').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.booking-slide').find('.bootstrap-select').removeClass('open');
    });

    // Slick slider trying out.
    $('.slickoffers').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });


    var startbookinganiTimer = '';
    // Start booking slide on mouse leave.
    $('.booking-slide').mouseleave(function () {
        clearTimeout(startbookinganiTimer);
        startbookinganiTimer = setTimeout(function () {
            // Start slider
            $('.booking-slide').slick('slickPlay');
            //  console.log('startit');
        }, 200);
    });



    /**
     * Menu script for top navigation.
     */
    function resetDropdownFolds() {
        // Reset .active classes in the dropdown-wide
        $('.dropdown-wide').find('.active').removeClass('active');

        // Hide all icons
        $('.foldicon').hide();

        // Hide all L3 folds.
        $('.dropdown-sub').hide();

        $('.dropdown-wide').find('.nav-dropdown-l2').find('a.nav-link').each(function () {
            // Check if active trail exitst
            if ($(this).hasClass('active-menu-trail')) {

                // Show active-menu-trail icon
                $(this).closest('.dropdown-wide').find('#l3-' + $(this).attr('data-iconid')).show();

                // Show active-menu-trail fold-down
                $(this).closest('.dropdown-wide').find('#foldicon' + $(this).attr('data-iconid')).show();

                // If no active fold found - show first.
            } else {

                // Check that none of the others a has active menu trail.
                if($(this).closest('ul').find('a.active-menu-trail').length == 0){
                    // Change first link to active
                    $(this).closest('ul').find('li').first().find('a').addClass('active');

                    // Show first icon
                    $(this).closest('.dropdown-wide').find('.foldicon').first().show();

                    // Show first fold-down
                    $(this).closest('.dropdown-wide').find('.dropdown-sub').first().show();
                }

            }
        });
    }

    // Dropdowns mouse leave behaviours
    var hidedropdownTimer = '';

    // Closes fold, used by functions that need to close the dropdown fold.
    function closeDropdownFold(){
        clearTimeout(hidedropdownTimer);

        // Remove active hover on menu button
        $('#topmenu').find('li.nav-item').find('.nav-link').removeClass('active');

        // Remove fold down class to background div.
        $('.foldbkg').removeClass('lc-show');

        // Remove fold down class from all link-div.
        $('.dropdown-wide').removeClass('lc-show');

        // Start slider again.
        $('.booking-slide').slick('slickPlay');
    }

    // Hide dropdown on li exit if, if cursor is not above the dropdown. Timeout due to IE.
    $('#topmenu').mouseleave(function (event) {
        clearTimeout(hidedropdownTimer);
        hidedropdownTimer = setTimeout(function () {
            if ($('.dropdown-wide:hover').length == 0) {
                closeDropdownFold();
            }
        }, 200);
        event.preventDefault();
    });

    // Hide dropdown on div exit, if cursor is not above the top menu. Timeout due to IE.
    $('.dropdown-wide').mouseleave(function (event) {
        clearTimeout(hidedropdownTimer);
        hidedropdownTimer = setTimeout(function () {
            if ($('#topmenu:hover').length == 0) {
                closeDropdownFold();
            }
        }, 200);
        event.preventDefault();
    });


    var allowedToClose = false;
    var allowedToCloseTimer = '';

    // Show top-menu (level 1) dropdowns on hover.
    $('#topmenu').find('li.nav-item.lc-dropdown-wide').find('a').on('click mouseenter touchstart', function (event) {
        // If close in progress, interupt
        clearTimeout(hidedropdownTimer);

        // Add active class on "hover"
        $('#topmenu').find('li.nav-item').find('.nav-link').removeClass('active');
        $(this).addClass('active');

        resetDropdownFolds();

        // Get fold id
        var dataTargetId = '';
        if ($(this).data('target') !== undefined) {
            // var dataTargetId = $(this).data('target').substring(1);
            dataTargetId = $(this).data('target');
        }

        // If fold id exists for button
        if (dataTargetId !== '') {

            // Add fold down class to link-div, if there isn't one.
            if($(dataTargetId).hasClass('lc-show') === false){
                // Add fold down class to background div.
                $('.foldbkg').addClass('lc-show');

                // Add fold down to target.
                $(dataTargetId).addClass('lc-show');

                clearTimeout(allowedToCloseTimer);
                allowedToCloseTimer = setTimeout(function () {
                    allowedToClose = true;
                }, 250);
            } else {
                // Close on click 2
                if( event.type === 'click' || event.type === 'touchstart' ){
                    if(allowedToClose === true){
                        // Disabled due to bug on ipad.
                        closeDropdownFold();
                        allowedToClose = false;
                    }
                }
            }
            
            // Remove fold down class from other link-divs.
            $('.dropdown-wide').not(dataTargetId).removeClass('lc-show');
        } else {
            // Remove fold down class to background div.
            $('.foldbkg').removeClass('lc-show');
            
            // Remove fold down class from all link-div.
            $('.dropdown-wide').removeClass('lc-show');
        }

        event.preventDefault();
        event.stopImmediatePropagation();
    });


    // Show top-menu (level 2) dropdowns on hover.
    $('.nav-dropdown-l2').find('li.nav-item').find('a').on('click mouseenter touchstart', function (event) {
        // If close in progress, interupt
        clearTimeout(hidedropdownTimer);

        // Remove hover classes from other l2 buttons.
        $(this).closest('.dropdown-wide').find('.nav-dropdown-l2').find('a.nav-link').removeClass('active');

        // Add hover class to this l2 button
        $(this).addClass('active');

        // Show correct icon
        $('.foldicon').hide();
        $('#foldicon' + $(this).attr('data-iconid')).show();

        // Hide all fold
        $(this).closest('.dropdown-wide').find('.dropdown-sub').hide();

        // Show hover fold
        $('#l3-' + $(this).attr('data-iconid')).show();

        event.preventDefault();
    });



    // TODO: refactor into own js module.
    /**
     * Mobile menu script for top navigation.
     */
    // Reset on menu-active-trail on open.
    $('button.navbar-toggler.collapsed').on('click', function (event) {
        // Reset active menu trail
        $('#mobile-menu').find('.active').removeClass('active');
        $('#mobile-menu').find('.collapse').collapse('hide');

        // Add active to menu trail, and open.
        $('#mobile-menu').find('.active-menu-trail').addClass('active');
        $('#mobile-menu').find('.active-menu-trail').next().addClass('show');
    });

    // Mobile-Login-menu collapse. Show the fixed bottom buttons. Due to IOS scroll problems.
    $('#mobile-menu').on('show.bs.collapse', function (event) {
        $('#mobile-menu-bottombar').collapse('show');
    });
    $('#mobile-menu').on('hide.bs.collapse', function () {
        $('#mobile-menu-bottombar').collapse('hide');
    });

    // Bootstrap v4 collapsable navigation nested extension by Markus.
    $('#mobile-menu').find('.nav-link').click(function () {

        if ($(this).hasClass('active')) {
            // Close
            $(this).removeClass('active');
            $(this).next('.collapse').collapse('hide');
        } else {
            // Open
            $(this).closest('ul').find('.active').removeClass('active');
            $(this).addClass('active');

            $('#mobile-menu :not(:has(.active))').find('.collapse').collapse('hide');
            $(this).next().collapse('toggle');
        }
    });

    $('#mobile-menu').on('hide.bs.collapse', function () {
        $('#main-navigation').removeClass('transp-nav');
        //   $('.mobile-slogan').show();
    });
    $('#mobile-menu').on('show.bs.collapse', function () {
        $('#main-navigation').addClass('transp-nav');
        // $('.mobile-slogan').hide();
    });


    /**
     * Footer menu tree expand/hide.
     */
    // Init expansion for lc-tabs
    $('.footer-nav').find('ul').collapse({
        'toggle':false
    });

    // Show all active
    $('.footer-nav').find('a.active').closest('ul').collapse('show');

    $('.footer-nav').find('li.nav-item').find('a').click(function (event) {

        if ($(this).hasClass('active')) {
            // Close
            $(this).removeClass('active');
            $(this).next('ul').collapse('hide');
            // Reset child uls
            $(this).next('ul').find('ul').collapse('hide');
            $(this).next('ul').find('a').removeClass('active');
        } else {
            // Close others
            $(this).closest('div').find('ul').not([
//               $(this).parentsUntil('.div').parents('ul')
                $(this).parentsUntil('div')
            ]).collapse('hide');

            $(this).closest('div').not([
//                $(this).parentsUntil('.div')
            ]).find('ul').find('a').removeClass('active');

            $(this).parentsUntil('.footer-nav').parents('ul').collapse('show');

            // Open
           // $(this).addClass('active');
            $(this).next('ul').collapse('show');
        }

        /*
        // Hide all others
        $('.footer-nav').find('.nav-item').removeClass('active');

        // Show parents for this
        $(this).parentsUntil('.footer-nav').('background-color','yellow');

        if ($(this).closest('.nav-item').hasClass('active')) {
            $(this).closest('.nav-item').removeClass('active');
        } else {
            // Add class
            $(this).closest('.nav-item').addClass('active');
        }

        */
        //event.preventDefault();
    });


    /**
     *  Scroll into view, helper function.
     */
    window.scrollIntoViewIfNeeded = function(target){

        // If target exists
        if(target.length > 0){

            // Mark as "not scrolled", for development and debugging.
            var newPos = target.offset();

            // How far to the bottom edge of the viewport is the top of target element allowed to be.
            var tresholdEndPercentage = 30;
            // How far to the top edge of the viewport is the top of target element allowed to be.
            var tresholdStartPercentage = 30;

            // Viewport subtraction, since we use negative padding-top for the body element. Uses navbar height to get correct px, since we use rem.
            var paddingTopThreshold = $('nav.navbar').height();

            var viewportStartPos = $(window).scrollTop() - paddingTopThreshold;
            var viewportEndPos = $(window).scrollTop() - paddingTopThreshold + $(window).height();


            // Calculate px from percentage since we use rem.
            var tresholdStartPx = parseInt(($(window).height() / 100) * tresholdStartPercentage);
            var tresholdEndPx = parseInt(($(window).height() / 100) * tresholdEndPercentage);

            // If target position is below or above the current viewport
            if((newPos.top + tresholdEndPx > viewportEndPos) || newPos.top - tresholdStartPx < viewportStartPos) {

                var scrollToNewPosition = parseInt(newPos.top) - paddingTopThreshold - tresholdStartPx;

                $('html, body').animate({
                    // Scroll to first element in replace/html.
                    scrollTop: scrollToNewPosition
                }, 400);
            }
        }
    }

    /**
     * Script for expand/hide sections.
     */
    $('.lc-btn-expand').on( "click touchevent", function() {
        $('.lc-btn-expand').removeClass('active-menu-trail');
        $(this).addClass('active-menu-trail');
        // Get button order
        var tabOpenIndex = [].indexOf.call($('.lc-btn-expand'), this);

        var expandTarget = $('.lc-tab-expand:eq('+ tabOpenIndex +')');

        // Hide all expandable tabs, expect the one opening, if already opened.
        $('.lc-tab-expand').not(expandTarget).removeClass('lc-tab-expand-open');

        // Remove classes for button close animation
        $('.lc-tab-expand').removeClass('lc-tab-expand-close');

        // Show selected expandable tab
        expandTarget.addClass('lc-tab-expand-open');

        scrollIntoViewIfNeeded(expandTarget);
    });

    $('.LC-btn-expand').on( "click", function() {
        $('.LC-btn-expand').removeClass('active-menu-trail');
        $(this).addClass('active-menu-trail');
        // Get button order
        var tabOpenIndex = [].indexOf.call($('.LC-btn-expand'), this);
        // Hide all expandable tabs
        $('.lc-tab-expand').collapse('hide');
        // Show selected expandable tab
        $('.lc-tab-expand:eq('+ tabOpenIndex +')').collapse('show');
    });

    $('.lc-tab-btn-close').on( "click touchevent", function() {
        $(this).closest('.lc-tab-expand').addClass('lc-tab-expand-close');

        var closeTarget = $(this).closest('.lc-tab-expand');

        // Timeout on close
        setTimeout(function(){
            closeTarget.removeClass('lc-tab-expand-open');
            closeTarget.removeClass('lc-tab-expand-close');
            $('.lc-btn-expand').removeClass('active-menu-trail');
            aos.refresh();
        }, 500);
    });


    function ShowImage(imgSrc) {
        $("#imgFull").attr("src", imgSrc);
        $("#imgFull").load(function(){  //This ensures image finishes loading
            //alert($("#imgFull").height());
            $("#imgFullView").width($("#imgFull").width());
            $("#imgFullView").height($("#imgFull").height());
            show_modal('imgFullView', true);
        }); 
    }

    $('[data-youtube]').click(function () {
        var videoID = $(this).attr('data-youtube');
        var img = $(this).find('img'); 
        videoHeight = $(img).height();
        var src = '//www.youtube.com/embed/'+ videoID + '?autoplay=1';
        var videoTarget = $(this).attr('data-target'); 
        var iframe = '<iframe id="youtube" width="100%" height="' + videoHeight + '" frameborder="0" src="'+src+'" allowfullscreen></iframe>';
        $(this).hide();
        $("." + videoTarget).html(iframe);
        return false; 
    });


    /**
     *  Cookie information
     */
    // Get cookie
    var cookieInfoClosedByUser = Cookies.get('cookieInfoClosedByUser');

    // If cookie not found, show cookie information.
    if(cookieInfoClosedByUser != 'infohidden' ) {
        // Add show class to cookie info div.
        $('.cookie-info').addClass('cookie-info-show');
    }

    // Eventhandler for cookie close
    $('.cookie-close').on('click touchstart', function() {
        Cookies.set('cookieInfoClosedByUser', 'infohidden', { expires: 365 });
        $('.cookie-info').removeClass('cookie-info-show');
    });

    /**
     *  Important information
     */
    // Get message ID - used to make sure a user has got the information, good if new information is pushed out.
    var importantInfoID = $('.informationmodal').data('importantinfoid');

    // Get cookie
    var importantInfoClosedByUser = Cookies.get(importantInfoID);

    // If cookie not found, show important information.
    if(importantInfoClosedByUser != 'infohidden' ) {

        // Show information modal.
        $('.informationmodal').modal('show');
    }
    // Setting cookie on modal close.
    $('.informationmodal').on('hide.bs.modal', function () {
        // do something…
        Cookies.set(importantInfoID, 'infohidden', { expires: 1 });
    });


    /**
     * Script for expand/hide next element, used for expand lists and more.
     * Currently last, because we've not divided this whole document.ready into modules.
     */
    //    var expandlist = require('../mod/expandlist/block-module-_block-module-41-happyindexbkgimgwide.scss.js');

    var expandlist = require('../modules/expandlistfaq/block-module-expandlistfaq.js');
    var lcMod1_socialandnews = require('../modules/lc-mod-1-socialandnews/block-module-1-socialandnews.js');
    var lcMod3_callme = require('../modules/lc-mod-3-callme/block-module-3-callme');
    var lcMod5_happyindexstart = require('../modules/lc-mod-5-happyindexstart/block-module-5-happyindexstart.js');
    var lcMod33_people = require('../modules/lc-mod-33-people/block-module-lc-mod-33-people.js');
    var bloglayout38 = require('../modules/lc-mod-38-blog/block-module-38-blog.js');
    //var lcMod_41_happyindexBkg = require('../mod/lc-mod-41-happyindexbkgimgwide/block-module-41-happyindexbkgimgwide.js');
    // var lcMid42_bookingcalendar = require('../mod/lc-mod-42-bookingcalendar/block-module-42-bookingcalendar.js');
    var lcMod_39_paraimgwide = require('../modules/lc-mod-39-paraimgwide/block-module-39-paraimgwide.js');
    var lcMod_43_booking_pri = require('../modules/lc-mod-43-booking-pri/block-module-booking-pri.js');
    //var lcMod_43_booking_pri_hemstadning = require('../mod/lc-mod-43-booking-pri/block-module-booking-pri-hemstadning.js');

    var lcMod_44_plusminus = require('../modules/lc-mod-44-plusminus/block-module-44-plusminus.js');
//    var lcMod_44_plusminus = require('../mod/lc-mod-44/block-module-booking-pri-hemstadning.js');

    /**
     * User input tests - only in jQuery now, will try finding one solution that works the same in webdriver.io and locally for css.
     */
    var userInputSimulation = require('../js/userinputsimulation.js');

//    var lcMod_44_plusminus = require('../modules/lc-mod-44-plusminus/block-module-44-plusminus.js');

    var block_module_big_bookingform_step1 = require('../modules/big-bookingform-step1/block_module-big-bookingform-step1.js');
    var block_module_46_booking_form = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form.js');
    var block_module_46_booking_form_validation = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-validation.js');
    var block_module_46_booking_form_navigation = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-navigation.js');
    var block_module_46_booking_form_interactions = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-interactions.js');
    var block_module_46_booking_form_price = require('../modules/lc-mod-46-booking-form/block-module-46-booking-price.js'); 
    var block_module_46_booking_form_input_count = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-input-count.js');
    var block_module_46_booking_form_checkbox_show = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-checkbox-show.js');
    var block_module_46_booking_form_radio = require('../modules/lc-mod-46-booking-form/block-module-46-booking-form-radio.js');




    var block_module_47_inquiry_form = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form.js');
    var block_module_47_inquiry_form_validation = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-validation.js');
    var block_module_47_inquiry_form_navigation = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-navigation.js');
    var block_module_47_inquiry_form_interactions = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-interactions.js');
 //   var block_module_47_inquiry_form_price = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-price.js');
    var block_module_47_inquiry_form_input_count = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-input-count.js');
    var block_module_47_inquiry_form_select = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-select.js');
    var block_module_47_inquiry_form_numberfree = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-numberfree.js');
    var block_module_47_inquiry_form_addmore = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-addmore.js');


    var block_module_47_inquiry_form_checkbox_show = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-checkbox-show.js');

    var block_module_48_breadcrumbs_page = require('../modules/lc-mod-48-breadcrumbs-page/block-module-48-breadcrumbs-page.js');


 //   var block_module_47_inquiry_form_radio = require('../modules/lc-mod-47-inquiry-form/block-module-47-inquiry-form-radio.js');





    
    // Global calendar function library.

/*
    var bookingCalendar = function() {
        console.log('yeah');
        return 'hepp';
    }
    */
});

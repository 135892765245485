// Default calendar options to be overriden.
var options = {
    //   first_day: 1,
    //   view: 'week',
    language: 'sv-SE',
    tmpl_cache: true,
    events_cache: true,
    weekbox: false,

    //    day: '2013-03-12',

    // Development and default path
    tmpl_path: 'theme/calendartpl/',

    // Development and default path
    events_source: '/lcsthlm.com/subdomains/mv2/dist/tw/pri/forms/includes/get-date.cfm?p=' + $('#f_servicePrice').val(),

    events_source: 'tw/pri/forms/includes/get-date.cfm?p=' + $('#f_servicePrice').val(),


    onAfterEventsLoad: function (events) {




        if (!events) {
            return;
        }
        var list = $('#eventlist');
        list.html('');

        $.each(events, function (key, val) {
            $(document.createElement('li'))
                .html('<a href="' + val.url + '">' + val.title + '</a>')
                .appendTo(list);
        });

        $('.events-list').click(function () {
            var dateSelected = moment($(this).data('cal-start')).format('YYYY-MM-DD');
            $('#added').val(dateSelected);
        });

        // Call the calendarlist function
        calendarlist(undefined,0,events);



        /**        $('#calendar-list').find('button').click(function () {

            // Cal-start selector
//            var dateSelected = moment($(this).data('cal-start')).format('YYYY-MM-DD');
//            $('#added').val(dateSelected);


            // TODO: add better selector here, should share data with desktop, would be the best.
            // Not sure if desktop should populate data in DOM.
            // Isn't needed.
            // Reference to the event, and then get data is better.
            // If there is not a very good reason
            // The event data could be returned to a value, if that is needed for general form procoessing.

        //    var dateSelected = moment($(this).closest('.events-list').data('cal-start')).format('YYYY-MM-DD');
        //    showServiceStartTime($(this), dateSelected);
        });
         */
    },
    onAfterViewLoad: function (view) {


        $('.page-header').find('.date-title').text(this.getTitle());
        $('.btn-group button').removeClass('active');
        $('button[data-calendar-view="' + view + '"]').addClass('active');
        // Populate date, desktop
        $('.events-list').find('a').click(function () {
            var dateSelected = moment($(this).closest('.events-list').data('cal-start')).format('YYYY-MM-DD');
            showServiceStartTime($(this), dateSelected);
        });

        $('#calendar').find('.loading').remove();


    },
    classes: {
        months: {
            general: 'label'
        }
    },
    views: {
        year: {
            slide_events: 0,
            enable: 1
        },
        month: {
            slide_events: 0,
            enable: 1
        },
        week: {
            enable: 0
        },
        weekday: {
            enable: 1
        },
        day: {
            enable: 1
        }
    },
    templates: {
        year: '',
        month: '',
        week: '',
        weekday: '',
        day: ''
    }
};


// Calendar languages in global scope.
if (!window.calendar_languages) {
    window.calendar_languages = {};
}

window.calendar_languages['sv-SE'] = {
    error_noview: 'Kalender: Vy {0} ej funnen',
    error_dateformat: 'Kalender: Felaktigt datumformat {0}. Skall vara antingen "now" eller "yyyy-mm-dd"',
    error_loadurl: 'Kalender: Laddnings-URL för händelser är ej satt',
    error_where: 'Kalender: Felaktig navigeringsriktning {0}. Kan endast vara "next", "prev" eller "today"',
    error_timedevide: 'Kalender: Parameter till tidsavgränsare måste dela 60 med ett heltal. Till exempel 10, 15, 30',

    title_year: '{0}',
    title_month: '{0} {1}',
    title_week: 'Vecka {0} {1}',
    title_day: '{0} {1} {2} {3}',

    week: 'Vecka {0}',
    all_day: 'Hela dagen',
    time: 'Tid',
    events: 'Händelser',
    before_time: 'Slutar före tidsperiod',
    after_time: 'Börjar efter tidsperiod',

    m0: 'Januari',
    m1: 'Februari',
    m2: 'Mars',
    m3: 'April',
    m4: 'Maj',
    m5: 'Juni',
    m6: 'Juli',
    m7: 'Augusti',
    m8: 'September',
    m9: 'Oktober',
    m10: 'November',
    m11: 'December',

    ms0: 'Jan',
    ms1: 'Feb',
    ms2: 'Mar',
    ms3: 'Apr',
    ms4: 'Maj',
    ms5: 'Jun',
    ms6: 'Jul',
    ms7: 'Aug',
    ms8: 'Sep',
    ms9: 'Okt',
    ms10: 'Nov',
    ms11: 'Dec',

    d0: 'Söndag',
    d1: 'Måndag',
    d2: 'Tisdag',
    d3: 'Onsdag',
    d4: 'Torsdag',
    d5: 'Fredag',
    d6: 'Lördag',

    easter: 'Påsk',
    easterMonday: 'Måndagen efter påsk',

    first_day: 1,
    week_numbers_iso_8601: true,

    holidays: {
        '01-01': "Nyårsdagen",
        '06-01': "Trettondedag jul",
        'easter-2': "Långfredagen",
        'easter': "Påskdagen",
        'easter+1': "Annandag påsk",
        '01-05': "Första maj",
        'easter+39': "Kristi himmelsfärdsdag",
        'easter+49': "Pingstdagen",
        '06-06': "Sveriges nationaldag"
    }
};




function calendarEvent (calendar) {
    $('.btn-group button[data-calendar-nav]').each(function () {
        var $this = $(this);

        // Unbind and solve with event namespacing:

        $this.unbind('click.' + 'calendar-nav-' + $this.data('calendar-nav'));

        $this.bind('click.' + 'calendar-nav-' + $this.data('calendar-nav'), function() {
            calendar.navigate($this.data('calendar-nav'));
        });
    });


    // TODO: also check that below event handlers is not set multiple times.
    // TODO: Make init function anyways.
    // TODO: Make more comments overall in this code.
    // TODO: Make it get data once. Keep as an object on navigation.
    // Check cache settings for the underscore templates.


    $('.btn-group button[data-calendar-view]').each(function () {
        var $this = $(this);
        $this.click(function () {
            calendar.view($this.data('calendar-view'));
        });
    });

    // List trigger, if needed.
    $('.btn-group button[data-calendar-list]').each(function () {
        var $this = $(this);
        $this.click(function () {
            calendarlist();
        });
    });


    $('#first_day').change(function () {
        var value = $(this).val();
        value = value.length ? parseInt(value) : null;
        calendar.setOptions({first_day: value});
        calendar.view();
    });

    $('#language').change(function () {
        calendar.setLanguage($(this).val());
        calendar.view();
    });

    $('#events-in-modal').change(function () {
        //var val = $(this).is(':checked') ? $(this).val() : null;
        //calendar.setOptions({modal: val});
    });
    $('#format-12-hours').change(function () {
        var val = $(this).is(':checked') ? true : false;
        calendar.setOptions({format12: val});
        calendar.view();
    });
    $('#show_wbn').change(function () {
        var val = $(this).is(':checked') ? true : false;
        calendar.setOptions({display_week_numbers: val});
        calendar.view();
    });
    $('#show_wb').change(function () {
        var val = $(this).is(':checked') ? true : false;
        calendar.setOptions({weekbox: val});
        calendar.view();
    });
    $('#events-modal .modal-header, #events-modal .modal-footer').click(function (e) {
        //e.preventDefault();
        //e.stopPropagation();
    });


    // On desktop, check if there is bookable events in view. Click next if not, only for the first 50 days.
    var numberOfBookable = $('#calendar').find('.bookable').length;
    if (numberOfBookable == 0) {
        $('.btn-group button[data-calendar-nav="next"]').click();
    }
}


/**
 * Inserts templates, with event prefix. Using underscores.
 * */

var callBackEventsFunc
function insertTemplate(ddselector, tpl, mdata, append, callBackEventsIn){


    // Set defaults
    var ptpl = typeof tpl !== 'undefined' ?  tpl : 'info.html';

    var bookableDates = mdata;

    // Add data to new array
    mdata.title = typeof mdata.title !== 'undefined' ?  mdata.title : '';
    mdata.body = typeof mdata.body !== 'undefined' ?  mdata.body : '';

    var elementToPopulate = $(ddselector);
    var templatePathb = options.tmpl_path + ptpl;
    var ustemplates;

    var templateName = ptpl.replace(".html","");

        // Load template from variables.
        ustemplates = {'selectorToPopulate': utemplates[templateName]};
        if(append === 1){
            elementToPopulate.append(ustemplates['selectorToPopulate']({"events": mdata})).ready(function() {
                afterAppend();
            });
        } else {
            elementToPopulate.html(ustemplates['selectorToPopulate']({"events": mdata})).ready(function() {
                afterAppend();
            });
        }

        function afterAppend(){
            if(callBackEventsIn === 'calendarlistButtonsEvents'){
                calendarlistButtonsEvents(bookableDates);
            }
            if(callBackEventsIn === 'calenderlistNavEvents'){
                calenderlistNavEvents(bookableDates);
            }
        }

}

/**
function insertTemplate(ddselector, tpl, mdata, append, callBackEventsIn){
    // Set defaults
    var ptpl = typeof tpl !== 'undefined' ?  tpl : 'info.html';

    var bookableDates = mdata;

    // Add data to new array
    mdata.title = typeof mdata.title !== 'undefined' ?  mdata.title : '';
    mdata.body = typeof mdata.body !== 'undefined' ?  mdata.body : '';

    var elementToPopulate = $(ddselector);
    var templatePathb = options.tmpl_path + ptpl;
    var ustemplates;

    $.ajax({
        url: templatePathb,
        dataType: 'html',
        type: 'GET',
        async: false,
        cache: true
    }).done(function(html) {
        ustemplates = {'selectorToPopulate': _.template(html)};
        if(append === 1){
            elementToPopulate.append(ustemplates['selectorToPopulate']({"events": mdata})).ready(function() {
                afterAppend();
            });
        } else {
            elementToPopulate.html(ustemplates['selectorToPopulate']({"events": mdata})).ready(function() {
                afterAppend();
            });
        }

        function afterAppend(){
            if(callBackEventsIn === 'calendarlistButtonsEvents'){
                calendarlistButtonsEvents(bookableDates);
            }
            if(callBackEventsIn === 'calenderlistNavEvents'){
                calenderlistNavEvents(bookableDates);
            }
        }
    });
}
*/

/**
 * Calendar list view for mobile.
 * */
var onlyOnceDayInit = '';

function calendarlist(dateSelectStart, append, bookableDatesNew) {

    var bookableDates;

    if(typeof dateSelectStart === "undefined"){
        dateSelectStart = moment().format('x');
    }
    if(typeof dateSelectEnd === "undefined"){
//        dateSelectEnd = moment().add(7,'days').format('x');
    }

    bookableDates = bookableDatesNew;

    bookableDates.startDate = dateSelectStart;

    // Detect last date
    var lastTimeStamp = 0;
    _.each(bookableDatesNew, function(searchLast){
        if(searchLast.start > lastTimeStamp){
            lastTimeStamp = parseInt(searchLast.start);
        }
    });

    bookableDates.lastDate = lastTimeStamp;

    // Date is selected.
    if(options.day !== 'now') {

        // Subtract 3 days from active day, but never subtract more than the active date.
        var newStartDate = parseInt(moment(options.day).subtract(3,'days').format('x'));


        // TODO: StartDate Issue here when going back, it needs to understand that it should go back.

        if (newStartDate >= parseInt(bookableDates.startDate)){
            if( onlyOnceDayInit != newStartDate ) {
                bookableDates.startDate = newStartDate;
                onlyOnceDayInit = newStartDate;
            }
        }
    }
    if(options.selectedDay !== 'now'){
        bookableDates.selectedDay = options.selectedDay;
    }


    if (bookableDates.length > 0) {
        // TODO: might refactor this, strange to have the object two times, why?
        bookableDates.rows = bookableDates;

        // Empty nav
//        $('#calendar-list-nav').empty();

        // Empty list
//        $('#calendar-list').empty();
//        calendarlistButtonsEvents()
//        calenderlistNavEvents()


        insertTemplate('#calendar-list-nav', 'calendarlistnav.html', bookableDates, 0, 'calendarlistButtonsEvents');
        insertTemplate('#calendar-list', 'bookablelist.html', bookableDates, append , 'calenderlistNavEvents');



        /**
         // List back button.
         $('.list-back').each(function () {
            var $this = $(this);
            $this.click(function () {

                // Only if more than 14 days visible
                if($this.closest('#calendar-list').find('.row').length > 14){

                    // Change scroll position.
                    var newPos = $this.closest('#calendar-list').find('.row:nth-last-child(21)').offset();
                    window.scrollTo(0,newPos.top);

                    // Remove latest
                    $this.closest('#calendar-list').find('.row').slice(-14).remove();

                    // Change next-button, subtract there too.
                    var loadmoreBackStart = moment(parseInt($this.closest('.morenav').find('.loadmore').attr('data-new-start'))).subtract(14,'days').format('x');
                    var loadmoreBackEnd = moment(parseInt($this.closest('.morenav').find('.loadmore').attr('data-new-end'))).subtract(14,'days').format('x');

                    $this.closest('.morenav').find('.loadmore').attr('data-new-start',loadmoreBackStart);
                    $this.closest('.morenav').find('.loadmore').attr('data-new-end',loadmoreBackEnd);
                }
            });
        });
         */
    }
}



// Event handlers for list
function calendarlistButtonsEvents(bookableDates){

    // Populate date selected, and click event handler
    $('#calendar-list').find('.booking-date').each(function () {

        var $this = $(this);
        $this.click(function () {
            var dateSelected = moment($(this).data('cal-start')).format('YYYY-MM-DD');
            onlyOnceDayInit = '';
            showServiceStartTime($(this), dateSelected);

            // Below is disabled since we got this in the main steps function currently.
            /**
            $('html, body').animate({
                // Scroll to first element in replace/html.
                scrollTop: $("#LC_step_2_edit_2").offset().top + 450
            }, 1000);
            */
        });
    });

    // Could be refactored into a single function
    // Listnav - update navigation
    $('.morenav').find('.list-nav').each(function () {

        var $this = $(this);
        $this.click(function () {
            $this.closest('.morenav').remove();

            // Clear view
            if ($this.data('clear-view') === 1) {
                $('#calendar-list').empty();
            }

            bookingCalendar.calendarlist($this.data('new-start'), 1, bookableDates);

            if($this.hasClass('next-month')){
                $('html, body').animate({
                    // Scroll to first element in replace/html.
                    scrollTop: $("#calendar-list-nav").offset().top - 200
                }, 1000);
            }


        });
    });
}

function calenderlistNavEvents(bookableDates){


    // Listnav - update navigation
    $('.month-navigation').find('.list-nav').each(function () {

        var $this = $(this);
        $this.click(function () {
            //$this.closest('.morenav').remove();

            // Clear view
            if($this.data('clear-view') === 1){
                $('#calendar-list').empty();
            }
            bookingCalendar.calendarlist($this.data('new-start'), 0, bookableDates);
        });
    });
}


/**
 * Init Calendar event handlers & buttons
 * */
var init = function init(el,optionsCustom) {

    // For development, replace tpl path if domain is local.
   var currentWindowUrl = window.location.href;
   if(currentWindowUrl.includes('/lcsthlm.com/subdomains/')){
       optionsCustom.tmpl_path =  '/lcsthlm.com/subdomains/mv2/dist/theme/calendartpl/';
   }
    if(currentWindowUrl.includes('/maries.se/dist')){
        optionsCustom.tmpl_path =  '/maries.se/dist/theme/calendartpl/';
    }


    // Extend options with custom options
    var optionsExtended = _.extend(options, optionsCustom);

    // Create new calendar
    var calendar = el.calendar(optionsExtended);

    // Add calendar events.
    calendarEvent(calendar);


    setTimeout(function(){
        //calendarUpdate();
        // Scroll into view
        if($("#calendar-list-nav").is(":visible")){
            window.scrollIntoViewIfNeeded($("#calendar-list-nav"));
        }
        if($("#calendar").is(":visible")) {
            window.scrollIntoViewIfNeeded($("#calendar"));
        }
    }, 100);




};

var update = function update(settings) {

};


/**
 * Export Public API
 */
module.exports.init = init;
module.exports.update = update;
module.exports.calendarlist = calendarlist;
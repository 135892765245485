


if ($('.lc-inquiry-form-container').length) {

    // Action on add edit container
    $(document).on('click touchstart', '.addeditcontainer', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        // Add to edit
        $(this).closest('.LC_edit.addmorecontainers').find('.container.hidden-xs-up').first().removeClass('hidden-xs-up');

        // Add to resume
        $('.LC_resume.addmorecontainers').find('.container.hidden-xs-up').first().removeClass('hidden-xs-up');

        // When no more to add, show only remove.
        if($(this).closest('.addmorecontainers').find('.container.hidden-xs-up').length == 0){
            $(this).closest('.container').hide();
        }
    });

    // Action on remove edit container
    $(document).on('click touchstart', '.removeeditcontainer', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        
        if ($(this).attr('data-target')) $('#' + $(this).data('target')).val('');

        // Remove from edit
        $(this).closest('.container').addClass('hidden-xs-up');

        // Reset all checkboxes and options.
        $(this).closest('.container').find('.lc-selectpicker').val('default');
        $(this).closest('.container').find('.lc-selectpicker').selectpicker("refresh");

        // Reset number/quantity
        $(this).closest('.container').find('.input-text-plusminus').val(0);
        $(this).closest('.container').find('.active-menu-trail').removeClass('active-menu-trail');


        var removedIndex = $($(this).closest('.container')).index();

        // Move container to last in edit mode
        $(this).closest('.container').insertBefore('.addmorebar');

        // Remove from resume
        $('.LC_resume.addmorecontainers').find('.container').eq(removedIndex).addClass('hidden-xs-up').remove().insertAfter($(".LC_resume.addmorecontainers .container:last"));

        // Allways show footer.
        $('.addmorebar').show();
    });


}


/**
 * User input simulation for css
 */

// Privat - hemstadning.

//    console.log('yeah');

    // Form step 1
    $('.lc-booking-form-pri').find('#c__delivery_address__postal_code').val('2730');
    $('.lc-booking-form-pri').find('#f_quantity').val('250');

    // Form step 2


    // Form step 3
if ($('.lc-inquiry-form-container').length) {


    // Action on Select - update hidden resume.
    $(document).on('change', '.lc-selectpicker.substepselect', function (e) {
        e.stopImmediatePropagation();

        var thisSelectID = $(this).attr('id');
        var thisResumeID = thisSelectID.replace('select', 'resume');

        $('#' + thisResumeID).find('a').html($(this).find(":selected").text());

        // Show selected disp
        if($(this).parent('.form-group.disp-select').length){

            // Show custom extra choices
            // Hide all
            $('.LC_edit').find('.disp-type').addClass('hidden-xs-up');
            // Reset all
            $('.LC_edit').find('.disp-type').find('.active-menu-trail').removeClass('active-menu-trail');
            $('.LC_edit').find('.disp-type').find('input[id]').val('0');


            $('.disp-type-' + $(this).find(":selected").val()).removeClass('hidden-xs-up');

            // Resume
            $('.LC_resume').find('.disp-type').addClass('hidden-xs-up');
        }

    });

}
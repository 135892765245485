var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;

if ($('.lc-booking-form-pri-slider').length > 0) {
  var loadURL = globalUrl + '/tw/pri/forms/hemstadning/slider-form.cfm';
	$('.lc-booking-form-pri-slider').load(loadURL);
}

if ($('.lc-booking-form-fst-slider').length > 0) {
  var loadURL = globalUrl + '/tw/fst/forms/trappstadning/slider-form.cfm';
  $('.lc-booking-form-fst-slider').load(loadURL);
}

if ($('.lc-booking-form-ftg-slider').length > 0) {
		var loadURL = globalUrl + '/tw/ftg/forms/lokalvard/slider-form.cfm';
		$('.lc-booking-form-ftg-slider').load(loadURL);
} 

if ($('.section-lc-mod-48-breadcrumbs-page').length) {

    $('.section-lc-mod-48-breadcrumbs-page').on('click','.tag-button',function(e) {
        e.stopImmediatePropagation();
        var $this = $(this);
        $('.filter-tag .btn').removeClass('btn-maries-blue');
        $this.addClass('btn-maries-blue');
        // Hide all sections
        $('.section-list').addClass('hidden-xs-up');
        // get the section to show
        sectionToShow = $this.attr('data-tagID');
     	
       	$('#' + sectionToShow).hide().removeClass('hidden-xs-up').fadeIn(700);
       	aos.refresh();
    });
};
var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;


if ($('.lc-booking-form-container').length) {

  // -----------------------------------------
  // Action on plus and minus button
  // -----------------------------------------
  $(document).on('click','.buttons-narrow-group-plusminus .btn-plus, .buttons-narrow-group-plusminus .btn-minus', function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    // get element ID
    var eltID = $this.attr('data-id');
    var $eltID = $('#' + eltID + '');
    // // console.log('eltID:' + eltID);

    var eltQuantity = $this.attr('data-quantity');
    var $eltQuantity = $('#' + eltQuantity + '');
    var eltQuantityVal = parseInt($eltQuantity.val());

    var eltPrice = $this.attr('data-price');
    var $eltPrice = $('#' + eltPrice + '');
    var eltPriceVal = parseInt($eltPrice.val());

    var eltTime = $this.attr('data-time');
    var $eltTime = $('#' + eltTime + '');
    var eltTimeVal = $eltTime.val(); 

    if ($this.hasClass('btn-plus')) {
      eltQuantityVal = eltQuantityVal + 1;
      $eltQuantity.val(eltQuantityVal);
    } 

    if ($this.hasClass('btn-minus') && eltQuantityVal >= 1) {
      eltQuantityVal = eltQuantityVal - 1;
      $eltQuantity.val(eltQuantityVal);
    }

    var eltPriceTot = eltQuantityVal * eltPriceVal;
    var eltTimeTot = eltQuantityVal * eltTimeVal; 

    // Set active-menu-trail if not zero
    if(eltQuantityVal > 0){
        $this.closest('.card').addClass('active-menu-trail');
        if ($this.closest('.card').parent().parent().parent().hasClass('LC_group')) {
          $groupID = $this.closest('.card').parent().parent().parent().attr('id');
          setActionGroupActive($groupID);
        }
    } else {
        $this.closest('.card').removeClass('active-menu-trail');
        if ($this.closest('.card').parent().parent().parent().hasClass('LC_group')) {
          $groupID = $this.closest('.card').parent().parent().parent().attr('id');
          setActionGroupActive($groupID);
        }
    }

    updateResumeInputCountBtn(eltID+'_resume',eltQuantityVal);

    // Only for windows
    if (($this).hasClass('window')) windowUpdate();

    if (($this).hasClass('polish1')) polish1Update();

    if (($this).hasClass('polish2')) polish2Update();


    // Strange, need to dooble to get back to 0
    if(eltQuantityVal ==  0) calculatePriceTime();
    calculatePriceTime();

  });

  window.setActionGroupActive = function($groupID) {
    $('.LC_edit .action-group').each(function() {
      if ($(this).data('id') == $groupID) {
        if ($('#' + $groupID).find('.active-menu-trail').length > 0) $(this).addClass('active-menu-trail');
        else $(this).removeClass('active-menu-trail');      }
    });
  }

  
    

  // -----------------------------------------
  // Action on plus minus text field
  // -----------------------------------------
  $(document).on('keypress','.input-text-plusminus', function(e) {
    e.stopImmediatePropagation();
    if ($(this).val().length > 1) return false;
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) return false;
  });

  // -----------------------------------------
  // Action on change plus minus text field
  // -----------------------------------------
  $(document).on('change','.input-text-plusminus', function(e) {
  
    e.stopImmediatePropagation();

    var $this = $(this);

    if ($this.val().length == 0) {
      $this.val(0);
    }

    var eltID = $this.attr('data-id');
    var $eltID = $('#' + eltID + '');
    
    var eltQuantityVal = parseInt($this.val());

    var eltPrice = $this.attr('data-price');
    var $eltPrice = $('#' + eltPrice + '');
    var eltPriceVal = parseInt($eltPrice.val());  

    var eltTime = $this.attr('data-time');
    var $eltTime = $('#' + eltTime + '');
    var eltTimeVal = $eltTime.val();  

    var eltPriceTot = eltQuantityVal * eltPriceVal;
    var eltTimeTot = eltQuantityVal * eltTimeVal;

    updateResumeInputCountBtn(eltID+'_resume',eltQuantityVal);

    // Only for windows
    if (($this).hasClass('window')) windowUpdate();

    calculatePriceTime();

  });


  // -----------------------------------------
  // Check Resume Form Group 
  // -----------------------------------------
  window.checkResumeFormGroup = function($step) {
    // Set variables
    showFormGroupIni = true; 
    nbFormGroup = 0; 
    nbFormGroupHidden = 0;
    // Go through all resumeFormgroup and check if hidden
    $('#' + $step + ' .LC_resume').find('.resumeFormGroup').each(function( index ) {
      if (!$(this).hasClass('ini')) {
        nbFormGroup++;
        if ($(this).parent().hasClass('hidden-xs-up')) nbFormGroupHidden++;
      }
    });
    // Return
    if (nbFormGroup != nbFormGroupHidden) showFormGroupIni = false;
    return showFormGroupIni;
  }

  window.updateResumeGroupBtn = function($this) {

    $('#' + $this + ' .LC_resume').find('.LC_group').each(function(index) {
      $group = $(this).data('id');
      $('#' + $group).parent().addClass('hidden-xs-up');
    });

    $('#' + $this + ' .LC_resume').find('.LC_group').each(function(index) {
      if ($(this).find('.in-group').hasClass('show')) {
        $group = $(this).data('id');
        $('#' + $group).parent().removeClass('hidden-xs-up');
      }
    });

  }

  window.updateResumeInputCountBtn = function($this,val) {

    var $resumeID = $('#' + $this);

    if (val == 0) {
      $resumeID.removeClass('show').parent().addClass('hidden-xs-up').removeClass('show');
    } else {
      $resumeID.addClass('show').parent().removeClass('hidden-xs-up').addClass('show');
      $resumeID.find('.number').html(val);
    }

    // Get LC_step
    var $stepToCheck = $resumeID.closest('.LC_step').attr('id');

    if (checkResumeFormGroup($stepToCheck) == true) {
      $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().removeClass('hidden-xs-up');
    } else {
      $resumeID.closest('.LC_resume').find('.resumeFormGroup.ini').parent().addClass('hidden-xs-up');
    }

    updateResumeGroupBtn($stepToCheck);
    
  }

  // -----------------------------------------
  // Polish 1 - Init the footer 
  // -----------------------------------------
  window.polish1GetCount = function() {

    var polish1_count = 0;
    
    $(".input-text-plusminus.polish1").each(function(index){
      polish1_count += parseInt($(this).val());
    });

    return polish1_count

  }

  window.polish1Update = function() {

    // Get the number of windows
    var polish1_count = polish1GetCount();

    $('#w__sub__child10_quantity').val(polish1_count);

    //  console.log('fct polish1Update');

  }

  // -----------------------------------------
  // Polish 1 - Init the footer 
  // -----------------------------------------
  window.polish2GetCount = function() {

    var polish2_count = 0;
    
    $(".input-text-plusminus.polish2").each(function(index){
      polish2_count += parseInt($(this).val());
    });

    return polish2_count

  }

  window.polish2Update = function() {

    // Get the number of windows
    var polish2_count = polish2GetCount();
    $('#w__sub__child11_quantity').val(polish2_count);

    // console.log('fct polish2Update');

  }

  // -----------------------------------------
  // Window - Init the footer 
  // -----------------------------------------
  window.windowGetCount = function() {

    var window_count = 0;
    
    $(".input-text-plusminus.window").each(function(index){
      window_count += parseInt($(this).val());
    });

    return window_count

  }

  window.windowUpdate = function($stepNr) {

    // Get the number of windows
    var window_count = windowGetCount();

    // Get the service
    currentService = $('#form-steps').data('service');

    if (window_count > 0) {
      // Update the footer if current service is fönsterputs
      if (currentService == 'fonsterputs') {
        // Get the current step
        if ($stepNr != null) currentStep = $stepNr;
        else currentStep = $('#LC_footer').find('.submitBtn').data('step');
        if ($('#'+currentStep).hasClass('window')) {
          $('#LC_footer').find('.submitBtn').removeClass('disabled');
          $('#LC_footer').find('.price').removeClass('hide');
        }
      }
      // Update the checkbox affiliate to windows
      $('.chkbox-form-price.window').each(function(index) {
        $(this).parent().show();
        if ($(this).is(':checked')) {
          // Get the quantity ID
          var QtID = $(this).data('quantity');
          $('#' + QtID).val(window_count);
        }
      });
    } else {
      // Update the footer if current service is fönsterputs
      if (currentService == 'fonsterputs') {
        // Get the current step
        if ($stepNr != null) currentStep = $stepNr;
        else currentStep = $('#LC_footer').find('.submitBtn').data('step');

        if ($('#'+currentStep).hasClass('window')) {
          $('#LC_footer').find('.submitBtn').addClass('disabled');
          $('#LC_footer').find('.price').addClass('hide');
        }
      }
      // Update the checkbox affiliate to windows
      $('.chkbox-form-price.window').each(function(index) {
        $(this).parent().hide();
        if ($(this).is(':checked')) {
          // Get the quantity ID
          var QtID = $(this).data('quantity');
          $('#' + QtID).val(0);
          // Unchecked 
          $(this).prop('checked',false);
        }
      });
    }


  }

  // -----------------------------------------
  // Action on change plus minus text field
  // -----------------------------------------
  $(document).on('change','.input-quantity', function(e) {
  
    e.stopImmediatePropagation();

    var $this = $(this);

    if ($this.val().length == 0) {
      $this.val(0);
    }

    var eltID = $this.attr('data-id');
    var $eltID = $('#' + eltID + '');
    
    var eltQuantityVal = parseInt($this.val());

    var eltPrice = $this.attr('data-price');
    var $eltPrice = $('#' + eltPrice + '');
    var eltPriceVal = parseInt($eltPrice.val());  

    var eltTime = $this.attr('data-time');
    var $eltTime = $('#' + eltTime + '');
    var eltTimeVal = $eltTime.val();  

    var eltPriceTot = eltQuantityVal * eltPriceVal;
    var eltTimeTot = eltQuantityVal * eltTimeVal;

    calculatePriceTime();

  });

}



var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;



  // Validator get error messages
  function getFormMsg(selector) {
  	return $(selector).attr('data-msg');
  }

  // Validator get error messages
  function validatePersonalNumber(input) {
    // Check valid length & form
    if (!input) return false;
    if (input.indexOf('-') == -1) return false;
    if (input.indexOf('-') == -1) {
        if (input.length === 10) {
            input = input.slice(0, 6) + "-" + input.slice(6);
        } else {
            input = input.slice(0, 8) + "-" + input.slice(8);
        }
    }
    if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) return false;
    // Clean input
    input = input.replace('-', '');
    if (input.length == 12) {
        input = input.substring(2);
    }
    
    // Declare variables
    var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6)-1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
            sum = 0,
            numdigits = input.length,
            parity = numdigits % 2,
            i,
            digit;
    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;
    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(input.charAt(i))
        if (i % 2 == parity) digit *= 2;
        if (digit > 9) digit -= 9;
        sum += digit;
    }
    return (sum % 10) == 0;
  };

  $.validator.addMethod("validateEmail",function(value, element) {
    if(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value))
    { return true;} else { return false; }    
  },"Please enter a valid Email.");

  $.validator.addMethod("personalNumber", function (value, element) {
    return validatePersonalNumber(value); 
  }, "wrong");

  $.validator.addMethod('oneCheckbox', function(value, ele) {
    console.log('ele checked: ' + $(ele).is(':checked'));
    return $(ele).is(':checked');
  }, "false");


  $.validator.addClassRules({
      forcevalidation: {
          required: true
      }
  });


if ($('.lc-booking-slider-container').length) {

	// -----------------------------------------
	// Jquery Validation Slider Pri
	// -----------------------------------------
	window.validatorFormPriSlider = function() {
  	$('.lc-booking-form-pri-slider form').validate({
	    onclick: false,
	    ignore: ":hidden",
	    rules: {
	      c__delivery_address__postal_code : { 
	        required:true, digits:true, maxlength:5, minlength:5,
	        remote: { 
            url: globalUrl + 'tw/pri/forms/includes/check-zip-code.cfm',
            data: {f__handler: $('#f__handler').val()},
            type: 'post',
            async: false,
            onkeyup: false
          }
	      },
	      f_quantity: {
	      	required:true, digits:true, maxlength:4, minlength:1
	      }
	    },
	    highlight: function (element) {
	        $(element).closest('.field').removeClass('has-success').addClass('has-danger');
	    },
	    unhighlight: function (element) {
	        $(element).closest('.field').removeClass('has-danger').addClass('has-success');
	        $(element).tooltip('dispose');
	    },
	    errorPlacement: function(error, element) {
	      msg = getFormMsg(element);
	      if (msg) {
	        $(element).attr('title', msg);
	        $(element).tooltip({'html':true,'trigger':'hover focus'});
	      }
	    }
	  });
  }
  

  // -----------------------------------------
  // Jquery Validation Slider fst
  // -----------------------------------------
  window.validatorFormFstSlider = function() {
    $('.lc-booking-form-fst-slider form').validate({
      onclick: false,
      ignore: ":hidden",
      rules: {
        c__delivery_address__postal_code : { 
          required:true, digits:true, maxlength:5, minlength:5,
          remote: { 
            url: globalUrl + 'tw/fst/forms/includes/check-zip-code.cfm',
            data: {f__handler: $('#f__handler').val()},
            type: 'post',
            async: false,
            onkeyup: false
          }
        },
        f_quantity: {
          required:true, digits:true, maxlength:4, minlength:1
        }
      },
      highlight: function (element) {
          $(element).closest('.field').removeClass('has-success').addClass('has-danger');
      },
      unhighlight: function (element) {
          $(element).closest('.field').removeClass('has-danger').addClass('has-success');
          $(element).tooltip('dispose');
      },
      errorPlacement: function(error, element) {
        msg = getFormMsg(element);
        if (msg) {
          $(element).attr('title', msg);
          $(element).tooltip({'html':true,'trigger':'hover focus'});
        }
      }
    });
  }

  // -----------------------------------------
  // Jquery Validation Slider ftgquanity
  // -----------------------------------------
  window.validatorFormFtgSlider = function() {
    $('.lc-booking-form-ftg-slider form').validate({
      onclick: false,
      ignore: ":hidden",
      rules: {
        c__delivery_address__postal_code : {
          required:true, digits:true, maxlength:5, minlength:5,
          remote: { 
            url: globalUrl + 'tw/ftg/forms/includes/check-zip-code.cfm',
            data: {f__handler: $('#f__handler').val()},
            type: 'post',
            async: false,
            onkeyup: false
          }
        },
        f_quantity: {
          required:true, digits:true, maxlength:4, minlength:1
        }
      },
      highlight: function (element) {
          $(element).closest('.field').removeClass('has-success').addClass('has-danger');
      },
      unhighlight: function (element) {
          $(element).closest('.field').removeClass('has-danger').addClass('has-success');
          $(element).tooltip('dispose');
      },
      errorPlacement: function(error, element) {
        msg = getFormMsg(element);
        if (msg) {
          $(element).attr('title', msg);
          $(element).tooltip({'html':true,'trigger':'hover focus'});
        }
      }
    });
  }

}  


if ($('.lc-booking-form-container').length) {

/**
    // -----------------------------------------
    // Jquery Validation Form Pri
    // -----------------------------------------
    jQuery.validator.addClassRules({
        forcevalidation: {
            required: true
        //  maxlength: 10
        }
    })

 */


  window.validatorFormContainer = function() {
  	$('.lc-booking-form-container form').validate({
      onkeyup: function (element,event) {},
      onfocusout: function (element) {
        this.element(element);
      },
      onclick: false,
      ignore: ":hidden",
      rules: {
        c__delivery_address__postal_code : { 
        	required:true, digits:true, maxlength:5, minlength:5,
          remote: { 
            url: globalUrl + 'tw/pri/forms/includes/check-zip-code.cfm',
            data: {f__handler: $('#f__handler').val()},
            type: 'post',
            async: false,
            onkeyup: false
          }
        },
        f_quantity: {
          required:true, digits:true, maxlength:4, minlength:1
        },
        c__last_name: {
          required: true
        },
        c__personal_number: {
          required: true, personalNumber: true
        },
        c__phone: {
          required: true
        },
        c__email: {
          required: true, validateEmail: true
        },
        c__delivery_address__address: {
          required: true
        },
        c__delivery_address__city: {
          required: true
        },
        servicePolishQt: {
          required: true
        }
      },
      highlight: function (element) {
        $(element).closest('.field').removeClass('has-success').addClass('has-danger');
      },
      unhighlight: function (element) {
          $(element).closest('.field').removeClass('has-danger').addClass('has-success');
          //$(element).tooltip('dispose');
      },
      //errorLabelContainer: '.LC_alert',
      errorContainer: "#LC_error",
      errorLabelContainer: "#LC_error alert",
      errorPlacement: function(error, element) {  
      // console.log(error)      
        $('.LC_alert').show();
        msg = getFormMsg(element);
        if (msg) {
          $(element).attr('title', msg);
          $(element).tooltip({'html':true,'trigger':'hover focus'});
        }
        
      }
    });


  }
  

  window.validatorCancelFormContainer = function() {

    $('.lc-booking-form-container form').validate({
      onkeyup: function (element,event) {},
      onfocusout: function (element) {
        this.element(element);
      },
      onclick: false,
      ignore: ":hidden",
      rules: {
        c__last_name: {
          required: true          
        }
      },
      highlight: function (element) {
        $(element).closest('.field').removeClass('has-success').addClass('has-danger');
      },
      unhighlight: function (element) {
        $(element).closest('.field').removeClass('has-danger').addClass('has-success');
      },
      //errorLabelContainer: '.LC_alert',
      errorContainer: "#LC_error",
      errorLabelContainer: "#LC_error alert",
      errorPlacement: function(error, element) {  
        console.log(error)      
        $('.LC_alert').show();
        msg = getFormMsg(element);
        if (msg) {
          $(element).attr('title', msg);
          $(element).tooltip({'html':true,'trigger':'hover focus'});
        }
        
      }
    });


  }


}

var globals = require('../../js/globals.js');
var globalUrl = globals.siteURL;
var globalLoaderCode = globals.loaderCode;


	// -----------------------------------------
	// Init loader with right HTML code
	// -----------------------------------------
	window.setLoaderHTML = function() {
		$('.LC_loader').each(function() {
			$(this).html(globalLoaderCode);
		});
	}

	// -----------------------------------------
	// Show LC Loader and hide what's needed
	// -----------------------------------------
	window.showLCLoader = function ($show, $hide) {
		if ($show != null) $show.addClass('show-loader');
		if ($hide != null) $hide.addClass('hide-loader');
	}

	// -----------------------------------------
	// Show LC Loader and hide what's needed
	// -----------------------------------------
	window.hideLCLoader = function () {
		$('.show-loader').removeClass('show-loader');
		$('.hide-loader').removeClass('hide-loader');
	}

	// -----------------------------------------
	// Show LC Alert
	// -----------------------------------------
	window.showLCAlert = function ($showAlert) {
		if ($showAlert != null) $showAlert.addClass('show-alert');
	}

	// -----------------------------------------
	// Show Top Error
	// -----------------------------------------
	window.showAlertError = function() {
		var $showAlert = $('.section-form-nobkg .LC_alert'); // alert to show
		showLCAlert($showAlert); // Show the alert
	}

if ($('.lc-booking-form-container').length) {
	
	// -----------------------------------------
	// Init form in general
	// -----------------------------------------
	window.formInitGeneral = function() {
		
		// Hide the alert
		$('.LC_alert').hide(); 
		

		// Check if there are some input already filled
		// And show the label
		$('.input input').each(function() {
	  	checkForInput(this);
		});

		// When an input is changed, show the label
		$('.input input').on('change keyup', function() {
	  	checkForInput(this);  
		});

		// Store last check, only keydown can detect which key, in case of backspace.
		var personalLastKey = '';

		// Store last key.
        $('#c__personal_number').keydown(function(e){
            personalLastKey = e.keyCode; // KeyCode 8, equals backspace.
		});


		$('#c__personal_number').keyup(function(e){
		  if(personalLastKey != 8){
			  $this = $(this);
			  if ($this.val().length >= 8) {
				if ($this.val().charAt(8) != '-') {
				  newVal = [$this.val().slice(0, 8), '-', $this.val().slice(8)].join('');
				  $this.val(newVal);
				}
			  }
          }
		});


		// Init some types of checkbox fields
		iniCheckbox(); 
		iniCheckboxGroup();
		iniCheckboxPrice();
		iniCheckboxPriceWindow();
		iniCheckboxComment();

		// Init some types of radio button
		iniRadioComment();

		// set HTML code to All Loader
		setLoaderHTML();

	}

}


if ($('.lc-booking-form-container').length) {

	// -----------------------------------------
	// Fill each loader with right code
	// ----------------------------------------- 
	setLoaderHTML();

	// -----------------------------------------
	// Load selected form service into container
	// -----------------------------------------
	var serviceName = $('.lc-booking-form-container').data('service'); // Get service name
    var serviceCategoryName = 'pri';// Get service name, set pri as default

    if($('.lc-booking-form-container').data('cat')){
        serviceCategoryName  = $('.lc-booking-form-container').data('cat');
    }

	// TOOD: can't always load pri here doesn't work.
  var loadURL = globalUrl + '/tw/' + serviceCategoryName + '/forms/' + serviceName + '/form-steps.cfm'; // Set url
  $('.lc-booking-form-container').load(loadURL); // Load url into container

  // -----------------------------------------
	// Show or hide label for input
	// -----------------------------------------
  window.checkForInput = function(element) {
  
  	var $label = $(element).siblings('label');

  	if ($(element).val().length > 0) {
    	$label.addClass('label-show');
    	$(element).closest('.field').addClass('has-success');
  	} else {
    	$label.removeClass('label-show');
  	}

	}

	

	
	// -----------------------------------------
	// Calculate price
	// -----------------------------------------
	window.calculatePrice = function(p,r,d) {
  	var cp;
  	
  	if (r == 1) deductionFct = 0.5;
  	else if (r == 2) deductionFct = 0.7;
  	else deductionFct = 1;

  	if (d>=100) cp = (((p * 1.25)*deductionFct) * (d/100));
  	else cp = (((p * 1.25)*deductionFct) * (1-(d/100)));
  	return cp;
  }


  

	// -----------------------------------------
	// Get Data for extra services
	// -----------------------------------------
	window.getExtraServices = function($qt) {
		// Set variables
		var selectedService = $('.lc-booking-form-container form').data('service');
        var selectedCat = 'pri';
        if($('.lc-booking-form-container form').data('cat').length > 1){
            selectedCat = $('.lc-booking-form-container form').data('cat');
        }

		var qt = $qt;
		// Ajax call for each extra service
		$('#extraServices .extraService').each(function() {
			var $this = $(this);
      var extraService = $this.attr('id');
      var ajxURL = globalUrl + 'tw/' + selectedCat + '/forms/' + extraService + '/get-service-data.cfm?s=' + selectedService;
			if (qt != null) ajxURL = globalUrl + 'tw/' + selectedCat + '/forms/' + extraService + '/get-service-data.cfm?s=' + selectedService + '&qt=' + $qt;
      $.ajax({
      	url: ajxURL,
      	method: 'get',
      	data: '',
      	dataType: 'json',
      	success: function(data){
      		// Success handling
      		var servicePrice = calculatePrice(parseFloat(data[0]['r2']),parseFloat(data[0]['r4']),parseFloat(data[0]['r6']));
      		var priceToshow = Math.round(servicePrice,2);
      		// Set the price
  				$this.find('.price').removeClass('ajx').html('från ' + priceToshow + ' sek');
  				checkAjaxStatus();
  			},
  			error: function(data, textStatus, jqXHR) {
      		// error handling
		      // console.log('error ' + extraService);
		    }
      });
    });
	}



	

  // -----------------------------------------
	// Extract step nr från ID
	// -----------------------------------------
	window.getStepNr = function(stepNrStr) {
		var str = stepNrStr;
		var arrStr = str.split(/_/g).slice(0);
		stepNr = arrStr[2];
		subStepNr = arrStr[4];
  	return [stepNr,subStepNr];
  	
  }

  // -----------------------------------------
	// ShowCalendar
	// -----------------------------------------
	window.showCalendar = function() {
		var str = stepNrStr;
		var arrStr = str.split(/_/g).slice(0);
		stepNr = arrStr[2];
		subStepNr = arrStr[4];
  	return [stepNr,subStepNr];
  }

  // -----------------------------------------
	// check checkAjaxStatus
	// -----------------------------------------
	window.checkAjaxStatus = function() {
		if ($('.lc-booking-form-container').find('.ajx').length == 0) {
			$('.show-loader').removeClass('show-loader');
			$('.hide-loader').removeClass('hide-loader');
			$('#extraServices').removeClass('hide');
		}
  }

  




	window.loader = function() {
		if ($('.waitAjax').length == 0) {
			hideLoaders();
		}
	}

	window.round = function(value, decimals) {
		return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
	}

	window.setDataAttr = function($this,dataID,dataVar){
		//// console.log('window.setDataAttr');
	  dataAttr = dataID.replace('data-', '');
		$this.removeData(dataAttr);
		$this.attr(dataID,dataVar);
	};

}

$(document).on('click','.printBtn', function(e) {
	console.log('printBtn');
  e.stopImmediatePropagation();
  $('#LC-response-box').printThis({
  		loadCSS: globals.siteURL + "/theme/assets/css/main.css"
  	}
  );
});








if ($('.lc-inquiry-form-container').length) {

    // Action on Select - update hidden resume.
    $(document).on('change', '.substepsinputnumberfree', function (e) {
        e.stopImmediatePropagation();

        var thisSelectID = $(this).attr('id');
        var thisResumeID = thisSelectID.replace('numberfreetext', 'resume');

        $('#' + thisResumeID).find('a').html($(this).val());
    });

}
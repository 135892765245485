exports['bookablelist']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var startMoment = moment(parseInt(events.startDate));


// Until 7 shown, or last day shown.

// Counts the number of buttons printed in each step.
var buttonPrinted = 0;

// Timestamp for the first date in view, used to determain month breaks.
var firstDateInView = '';

// Css class that is set when the month breaks into new month.
var outMonthClass = '';


var selectedClass = '';

for (counterMoment = moment(parseInt(events.startDate)); (buttonPrinted < 7) && (moment(events.lastDate).format('x') >= counterMoment.format('x')); counterMoment.add(1,'days')) {

    selectedClass = '';

    printedDate = 0;


    // Determain month break, add month break class
    if(firstDateInView !== ''){
        if(parseInt(moment.unix(firstDateInView).format('M')) < parseInt(counterMoment.format('M'))){
            outMonthClass = ' cal-list-outMonth';
        }
    }
    
__p+=' ';
// add events for this date..  filter or loop
__p+=' ';
 // Loop each for every date. - Only one for this project, but doesn't matter. 
__p+=' ';
 _.each(events.rows, function(event, selectedClass){
               if (moment(parseInt(event.start)).format('dddd D MMM') === counterMoment.format('dddd D MMM')  ){
                    // Css selected class
                    if(parseInt(moment(events.selectedDay).startOf('day').format('x')) === parseInt(counterMoment.startOf('day').format('x'))){
                    selectedClass = ' cal-day-selected';
                }

                // TODO: maybe add classes as an array that is split here instead, nicer code imo. 
__p+=' <div class="row aos-init aos-animate" data-aos="fade-up"> <div class="col-12"> <button type="button" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" class="btn btn-primary booking-date '+
((__t=( outMonthClass ))==null?'':__t)+
' '+
((__t=( selectedClass ))==null?'':__t)+
'" style="background-color: '+
((__t=(event.color ))==null?'':__t)+
'" data-cal-start="'+
((__t=( event.start ))==null?'':__t)+
'"> <span class="redDay hidden-xs-up">'+
((__t=( event.redDay ))==null?'':__t)+
'</span><span class="weekendDay hidden-xs-up">'+
((__t=( event.weekendDay ))==null?'':__t)+
'</span><span class="dayTimesPrice hidden-xs-up">'+
((__t=( event.dayTimesPrice ))==null?'':__t)+
'</span> <span class="dayTimesDiscountID hidden-xs-up">'+
((__t=( event.dayTimesDiscountID ))==null?'':__t)+
'</span> <span class="dayTimesDiscountPercent hidden-xs-up">'+
((__t=( event.dayTimesDiscountProcent ))==null?'':__t)+
'</span> <span class="datetxt">'+
((__t=( moment(parseInt(event.start)).format('ddd D MMM') ))==null?'':__t)+
'</span> ';
 if (event.discountMax > 0) { 
__p+=' <span class="discount-bubble"></span> ';
 } 
__p+=' ';
 if (event.price > 0) { 
__p+=' <span class="price">'+
((__t=( event.price ))==null?'':__t)+
' kr</span> ';
 } 
__p+=' </button> ';
 if (event.discountLeft > 0) { 
__p+=' <span class="discount-left">'+
((__t=( event.discountLeft ))==null?'':__t)+
' ST. KVAR TILL DETTA PRIS &uarr;</span> ';
 } 
__p+=' </div> </div> ';

                // Fix this, bug here.

                // Set initial date shown for the first date. To determain month-break
                if(firstDateInView === ''){
                    firstDateInView = counterMoment.format('X');
                }
                
__p+=' ';

                // TODO: I guess values below can be refactored into just on.

                // Count buttons printed
                buttonPrinted++;

                // Mark that we have printed something
                printedDate = 1;
            }
        }); 
__p+=' ';
 } 
__p+=' ';

// TODO: Base next on current date.. ok.



var nextMomentStart = counterMoment;

var nextMonthStart = moment.unix(firstDateInView);


// Get
nextMonthStart = nextMonthStart.startOf('month').add(1, 'months');

//nextMomentStart.add(7,'days');

//var prevMomentStart = moment(startMoment);
//var prevMomentEnd = moment(endMoment);
//prevMomentStart.subtract(7,'days');
//prevMomentEnd.subtract(7,'days');


if ( moment(events.lastDate).format('x') >= counterMoment.format('x') ) {
    if( outMonthClass === '' ){
    
__p+=' <div class="morenav"> <button type="button" class="lecms-btn-lightgrey list-nav loadmore" data-new-start="'+
((__t=( nextMomentStart.format('x') ))==null?'':__t)+
'">Nästa 7 dagar</button> </div> ';
 } else { 
__p+=' <div class="morenav"> <button type="button" class="lecms-btn-lightgrey list-nav loadmore next-month" data-clear-view="1" data-new-start="'+
((__t=( nextMonthStart.format('x') ))==null?'':__t)+
'">Nästa månad</button> </div> ';
 }
    } 
__p+=' ';
 //# sourceURL=bookablelist.html 
__p+='';
}
return __p;
};
exports['bookablelistOLD']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var startMoment = moment(parseInt(events.startDate));
var endMoment = moment(parseInt(events.endDate));

// TODO: change to loop until 7 shown. Always.
// Loop out days between start and endmoments
for (counterMoment = moment(parseInt(events.startDate)); endMoment.format('X') > counterMoment.format('X'); counterMoment.add(1,'days')) {
printedDate = 0;

__p+=' <div class="row aos-init aos-animate" data-aos="fade-up"> <div class="col-12"> ';
// add events for this date..  filter or loop
__p+=' ';
 if ( counterMoment.format('D') == 1){ 
__p+=' <div class="month-name text-center">'+
((__t=( counterMoment.format('MMMM') ))==null?'':__t)+
'</div> ';
 } 
__p+=' ';
 if ( counterMoment.format('d') == 1){ 
__p+=' <!--<strong>Vecka: '+
((__t=( counterMoment.format('w') ))==null?'':__t)+
'</strong>--> ';
 } 
__p+=' ';
 _.each(events.rows, function(booking){
           if (moment(parseInt(booking.start)).format('dddd D MMM') === counterMoment.format('dddd D MMM')  ){
        
__p+=' <button type="button" class="booking-date lecms-btn-lightgrey" data-cal-start="'+
((__t=( booking.start ))==null?'':__t)+
'" style="background-color: '+
((__t=( booking.color ))==null?'':__t)+
'"> <span class="buttonDay"></span> '+
((__t=( moment(parseInt(booking.start)).format('ddd D MMM') ))==null?'':__t)+
' <span class="mobile-price">'+
((__t=( booking.price ))==null?'':__t)+
'kr</span> </button><br><br> ';

        printedDate = 1;
        }
        }); 
__p+=' ';
 if (printedDate === 0){ 
__p+=' <!--        <button type="button" class="nobooking-date lecms-btn-lightgrey"> '+
((__t=( counterMoment.format('dddd D MMM') ))==null?'':__t)+
' </button><br><br>--> ';

        }
        
__p+=' </div> </div> ';
 } 
__p+=' ';

var nextMomentStart = moment(startMoment);
var nextMomentEnd = moment(endMoment);
nextMomentStart.add(7,'days');
nextMomentEnd.add(7,'days');

var prevMomentStart = moment(startMoment);
var prevMomentEnd = moment(endMoment);
prevMomentStart.subtract(7,'days');
prevMomentEnd.subtract(7,'days');


__p+=' <div class="morenav"> <button type="button" class="lecms-btn-lightgrey list-nav loadmore" data-new-start="'+
((__t=( nextMomentStart.format('x') ))==null?'':__t)+
'" data-new-end="'+
((__t=( nextMomentEnd.format('x') ))==null?'':__t)+
'">Nästa vecka</button> ';
if (startMoment.format('X') > moment().format('X')) { 
__p+=' <!--<br><br>\n    <button type="button" class="lecms-btn-lightgrey list-back" data-new-start="'+
((__t=( nextMomentStart.format('x') ))==null?'':__t)+
'">Tillbaka</button>--> ';
 } 
__p+=' </div> ';
 //# sourceURL=bookablelist.html 
__p+='';
}
return __p;
};
exports['calendarlistnav']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var startMoment = moment(parseInt(events.startDate));

var monthMoment = moment(parseInt(events.startDate)).startOf('month');

var navTopMomentBack = moment(parseInt(events.startDate));


navTopMomentBack  = parseInt(navTopMomentBack.startOf('month').subtract(1, 'months').format('x'));

var navTopMomentNext = moment(parseInt(events.startDate));
navTopMomentNext = parseInt(navTopMomentNext.startOf('month').add(1, 'months').format('x'));

// Avoid back in time
var currentMonthMoment = parseInt(moment().startOf('month').format('x'));


__p+=' <div class="month-navigation"> ';
 if ( navTopMomentBack >= currentMonthMoment ) { 
__p+=' <button type="button" class="lecms-btn-lightgrey list-nav loadmore month-button-back" data-clear-view="1" data-new-start="'+
((__t=( navTopMomentBack ))==null?'':__t)+
'" style="width:1rem">&#60;</button> ';
 } else { 
__p+=' <button type="button" class="lecms-btn-lightgrey month-button-off"></button> ';
 } 
__p+=' <button type="button" class="lecms-btn-lightgrey list-nav loadmore month-button-name" data-clear-view="1" data-new-start="'+
((__t=( monthMoment ))==null?'':__t)+
'">'+
((__t=( startMoment.format('MMMM') ))==null?'':__t)+
'</button> ';

    // Move one extra week into empty.
    if (navTopMomentNext <= moment(events.lastDate).format('x') ) { 
__p+=' <button type="button" class="lecms-btn-lightgrey list-nav loadmore month-button-next" data-clear-view="1" data-new-start="'+
((__t=( navTopMomentNext ))==null?'':__t)+
'" style="width:1rem">&#62;</button> ';
 } else {
__p+=' <button type="button" class="lecms-btn-lightgrey month-button-off"></button> ';
 } 
__p+=' </div> ';
 //# sourceURL=calendarlistnav.html 
__p+='';
}
return __p;
};
exports['day']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="cal-day-box"> <div class="row-fluid clearfix cal-row-head"> <div class="span1 col-xs-1 cal-cell">'+
((__t=( cal.locale.time ))==null?'':__t)+
'</div> <div class="span11 col-xs-11 cal-cell">'+
((__t=( cal.locale.events ))==null?'':__t)+
'</div> </div> ';
 if(all_day.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-1"><b>'+
((__t=( cal.locale.all_day ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-11"> ';
 _.each(all_day, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' ';
 if(before_time.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-3"><b>'+
((__t=( cal.locale.before_time ))==null?'':__t)+
'</b></div> <div class="span5 col-xs-5"> ';
 _.each(before_time, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <span class="cal-hours pull-right">'+
((__t=( event.end_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' <div id="cal-day-panel" class="clearfix"> <div id="cal-day-panel-hour"> ';
 for(i = 0; i < hours; i++){ 
__p+=' <div class="cal-day-hour"> ';
 for(l = 0; l < cal._hour_min(i); l++){ 
__p+=' <div class="row-fluid cal-day-hour-part"> <div class="span1 col-xs-1"><b>'+
((__t=( cal._hour(i, l) ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-11"></div> </div> ';
 }; 
__p+=' </div> ';
 }; 
__p+=' </div> ';
 _.each(by_hour, function(event){ 
__p+=' <div class="pull-left day-event day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'" style="margin-top: '+
((__t=( (event.top * 30) ))==null?'':__t)+
'px; height: '+
((__t=( (event.lines * 30) ))==null?'':__t)+
'px"> <span class="cal-hours">'+
((__t=( event.start_hour ))==null?'':__t)+
' - '+
((__t=( event.end_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> ';
 if(after_time.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-3"><b>'+
((__t=( cal.locale.after_time ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-9"> ';
 _.each(after_time, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <span class="cal-hours">'+
((__t=( event.start_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' </div> ';
}
return __p;
};
exports['events-list']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span id="cal-slide-tick" style="display: none"></span> <div id="cal-slide-content" class="cal-event-list"> <ul class="unstyled list-unstyled"> ';
 _.each(events, function(event) { 
__p+=' <li> <span class="pull-left event '+
((__t=( event['class'] ))==null?'':__t)+
'"></span>&nbsp; <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </li> ';
 }) 
__p+=' </ul> </div> ';
}
return __p;
};
exports['modal']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 	event.date_start = new Date(parseInt(event.start));
	event.date_end = new Date(parseInt(event.end)); 
__p+=' <div id="event-meta" class="pull-right"> <span>Starts on '+
((__t=( event.date_start.getDate() ))==null?'':__t)+
' '+
((__t=( calendar.locale["m" + event.date_start.getMonth()] ))==null?'':__t)+
' '+
((__t=( event.date_start.getFullYear() ))==null?'':__t)+
', at '+
((__t=( event.date_start.getHours() ))==null?'':__t)+
':'+
((__t=( event.date_start.getMinutes() ))==null?'':__t)+
' <i class="icon-time"></i></span><br> <span>Ends on '+
((__t=( event.date_end.getDate() ))==null?'':__t)+
' '+
((__t=( calendar.locale["m" + event.date_end.getMonth()] ))==null?'':__t)+
' '+
((__t=( event.date_end.getFullYear() ))==null?'':__t)+
' at '+
((__t=( event.date_end.getHours() ))==null?'':__t)+
':'+
((__t=( event.date_end.getMinutes() ))==null?'':__t)+
' <i class="icon-time"></i></span><br> </div> <div style="margin: 10px 0"> <a href="'+
((__t=( event.url ))==null?'':__t)+
'" class="btn btn-primary"><i class="icon-calendar"></i> More info</a> </div>';
}
return __p;
};
exports['month-day']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-month-day '+
((__t=( cls ))==null?'':__t)+
'';
 if (events.length > 0) { 
__p+=' bookable';
}
__p+='"> <span class="pull-left day-number" style="color: ';
 _.each(events, function(event) { 
__p+=''+
((__t=(event.daycolor ))==null?'':__t)+
'';
 }) 
__p+='">'+
((__t=( day ))==null?'':__t)+
' </span><span class="day-name-indicator">'+
((__t=(moment(start).format('dddd') ))==null?'':__t)+
' </span> ';
 if (events.length > 0) { 
__p+=' <div class="events-list" data-cal-start="'+
((__t=( start ))==null?'':__t)+
'" data-cal-end="'+
((__t=( end ))==null?'':__t)+
'"> ';
 _.each(events, function(event) { 
__p+=' <a data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" style="background-color: '+
((__t=(event.color ))==null?'':__t)+
'"> <span class="redDay hidden-xs-up">'+
((__t=( event.redDay ))==null?'':__t)+
'</span><span class="weekendDay hidden-xs-up">'+
((__t=( event.weekendDay ))==null?'':__t)+
'</span><span class="dayTimesPrice hidden-xs-up">'+
((__t=( event.dayTimesPrice ))==null?'':__t)+
'</span> <span class="dayTimesDiscountID hidden-xs-up">'+
((__t=( event.dayTimesDiscountID ))==null?'':__t)+
'</span> <span class="dayTimesDiscountPercent hidden-xs-up">'+
((__t=( event.dayTimesDiscountProcent ))==null?'':__t)+
'</span> ';
 if (event.price > 0) { 
__p+=' <h4 class="pull-right">'+
((__t=( event.price ))==null?'':__t)+
':-</h4> ';
 if (event.discountLeft > 0) { 
__p+=' <span class="discount-left">'+
((__t=( event.discountLeft ))==null?'':__t)+
' ST. KVAR TILL DETTA PRIS</span> ';
 } 
__p+=' ';
 } 
__p+=' ';
 if (event.discountMax > 0) { 
__p+=' <span class="discount-bubble"></span> ';
 } 
__p+=' </a> ';
 }); 
__p+=' </div> ';
 } 
__p+=' </div> ';
}
return __p;
};
exports['month']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-row-fluid cal-row-head"> ';
 _.each(days_name, function(name){ 
__p+=' <div class="cal-cell1">'+
((__t=( name ))==null?'':__t)+
'</div> ';
 }) 
__p+=' </div> <div class="cal-month-box"> ';
 for(i = 0; i < 6; i++) { 
__p+=' ';
 var thisWeek = parseInt(moment().tz('Europe/Stockholm').format('YYYYWW')); 
__p+=' ';
 var rowWeek = parseInt(moment.parseZone(cal.options.position.start).add(day,'days').format('YYYYWW')); 
__p+=' ';
 var hideWeek = '';
	
	if (thisWeek > (rowWeek) ){
	hideWeek = ' hide-week';
	} 
__p+=' ';
 if(cal.stop_cycling == true) break; 
__p+=' <div class="week-indicator"> <strong>Vecka: '+
((__t=( moment.parseZone(cal.options.position.start).add(day,'days').format('w') ))==null?'':__t)+
'</strong> </div> <div class="cal-row-fluid cal-before-eventlist'+
((__t=( hideWeek ))==null?'':__t)+
'"> <div class="cal-cell1 cal-cell" data-cal-row="-day1">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day2">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day3">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day4">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day5">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day6">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day7">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> ';
 } 
__p+=' </div> ';
}
return __p;
};
exports['monthWorking']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-row-fluid cal-row-head"> ';
 _.each(days_name, function(name){ 
__p+=' <div class="cal-cell1">'+
((__t=( name ))==null?'':__t)+
'</div> ';
 }) 
__p+=' </div> <div class="cal-month-box"> ';
 for(i = 0; i < 6; i++) { 
__p+=' ';
 if(cal.stop_cycling == true) break; 
__p+=' <div class="week-indicator"> <strong>Vecka: '+
((__t=( moment.parseZone(cal.options.position.start).add(day,'days').format('w') ))==null?'':__t)+
'</strong> </div> <div class="cal-row-fluid cal-before-eventlist"> <div class="cal-cell1 cal-cell" data-cal-row="-day1">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day2">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day3">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day4">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day5">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day6">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day7">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> ';
 } 
__p+=' </div> ';
}
return __p;
};
exports['montj_debug']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-row-fluid cal-row-head"> ';
 _.each(days_name, function(name){ 
__p+=' <div class="cal-cell1">'+
((__t=( name ))==null?'':__t)+
'</div> ';
 }) 
__p+=' </div> <div class="cal-month-box"> ';
 for(i = 0; i < 6; i++) { 
__p+=' ';
 var thisWeek = parseInt(moment().tz('Europe/Stockholm').format('w')); 
__p+=' ';
 var rowWeek = parseInt(moment.parseZone(cal.options.position.start).add(day,'days').format('w')); 
__p+=' ';
 var hideWeek = '';

    console.log('weekdebug');
    console.log(thisWeek);
    console.log(rowWeek);
    console.log(rowWeek -1);

    //	if (thisWeek > (rowWeek -1) ){
    if (thisWeek > rowWeek ){
    hideWeek = ' hide-week';
    } 
__p+=' ';
 if(cal.stop_cycling == true) break; 
__p+=' <div class="week-indicatorX"> <br><br> <strong>Vecka: '+
((__t=( moment.parseZone(cal.options.position.start).add(day,'days').format('w') ))==null?'':__t)+
'</strong> '+
((__t=( cal.options.position.start ))==null?'':__t)+
'<br> '+
((__t=( day  ))==null?'':__t)+
' <br><br> thisWeek: '+
((__t=( thisWeek))==null?'':__t)+
'<br> rowWeek: '+
((__t=( rowWeek))==null?'':__t)+
'<br> rowWeekMin1: '+
((__t=( rowWeek - 1))==null?'':__t)+
'<br> </div> <div class="cal-row-fluid cal-before-eventlist'+
((__t=( hideWeek ))==null?'':__t)+
'"> <div class="cal-cell1 cal-cell" data-cal-row="-day1">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day2">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day3">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day4">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day5">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day6">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> <div class="cal-cell1 cal-cell" data-cal-row="-day7">'+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> ';
 } 
__p+=' </div> ';
}
return __p;
};
exports['week-days']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(events, function(event){
__p+=' <div class="cal-row-fluid"> <div class="cal-cell'+
((__t=( event.days))==null?'':__t)+
' cal-offset'+
((__t=( event.start_day ))==null?'':__t)+
' day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" style="background-color: '+
((__t=( event.color))==null?'':__t)+
'"> <h4 class="pull-right">'+
((__t=(event.price ))==null?'':__t)+
'</h4> <div class="showonmobile"> '+
((__t=( moment(parseInt(event.start)).format('dddd') ))==null?'':__t)+
' '+
((__t=( moment(parseInt(event.start)).format('D') ))==null?'':__t)+
' '+
((__t=( moment(parseInt(event.start)).format('MMM') ))==null?'':__t)+
' </div> <!--		<a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" class="cal-event-week event'+
((__t=( event.id ))==null?'':__t)+
'">'+
((__t=( event.title ))==null?'':__t)+
'</a> --> </div> </div> ';
 }); 
__p+=' ';
}
return __p;
};
exports['week']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-week-box"> <div class="cal-offset1 cal-column"></div> <div class="cal-offset2 cal-column"></div> <div class="cal-offset3 cal-column"></div> <div class="cal-offset4 cal-column"></div> <div class="cal-offset5 cal-column"></div> <div class="cal-offset6 cal-column"></div> <div class="cal-row-fluid cal-row-head"> ';
 _.each(days_name, function(name) { 
__p+=' <div class="cal-cell1 '+
((__t=( cal._getDayClass('week', start) ))==null?'':__t)+
'" data-toggle="tooltip" title="'+
((__t=( cal._getHolidayName(start) ))==null?'':__t)+
'">'+
((__t=( name ))==null?'':__t)+
'<br> <small><span data-cal-date="'+
((__t=( start.getFullYear() ))==null?'':__t)+
'-'+
((__t=( start.getMonthFormatted() ))==null?'':__t)+
'-'+
((__t=( start.getDateFormatted() ))==null?'':__t)+
'" data-cal-view="day">'+
((__t=( start.getDate() ))==null?'':__t)+
' '+
((__t=( cal.locale['ms' + start.getMonth()] ))==null?'':__t)+
'</span></small> </div> ';
 start.setDate(start.getDate() + 1); 
__p+=' ';
 }) 
__p+=' </div> <hr> '+
((__t=( cal._week() ))==null?'':__t)+
' </div> ';
}
return __p;
};
exports['weekNew']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-week-box"> ';

	console.log('ping');
	console.log(cal);

	var day_count;
	day_count = 0;

	// Make this work, when month breaks.
	// Think some more.. ok.


	// TODO: Den printar inte rätt datum, utan dags nummer. fixa.. blir ju förvirrande =)
	// Stämmer inte, lös..
	// TODO: Fixa sen nav.. ok
	// Kör each?  Breaka, varje ny vecka med hjälp av moment istället?
	// Enda vettiga?


	for(i = 0; i < 6; i++) {
	day_count = 0;
	
__p+=' ';
 if(cal.stop_cycling == true) {
	break;
	} 
__p+=' <div class=""><strong>Vecka: '+
((__t=( moment.parseZone(cal.options.position.start).add(day,'days').format('w') ))==null?'':__t)+
'</strong></div> <div class="cal-row-fluid cal-before-eventlist"> <div class="row"> <div class="col-12"> '+
((__t=(console.log(day)))==null?'':__t)+
' <div class="" data-cal-row="-day1">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=(day_count++))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day2">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day3">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day4">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day5">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day6">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> <div class="row"> <div class="col-12"> <div class="" data-cal-row="-day7">'+
((__t=(days_name[day_count++]))==null?'':__t)+
' '+
((__t=( cal._day(i, day++) ))==null?'':__t)+
'</div> </div> </div> </div> ';
 } 
__p+=' </div> ';
}
return __p;
};
exports['weekday']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="cal-day-box"> <div class="row-fluid clearfix cal-row-head"> <div class="span1 col-xs-1 cal-cell">'+
((__t=( cal.locale.time ))==null?'':__t)+
'</div> <div class="span11 col-xs-11 cal-cell">'+
((__t=( cal.locale.events ))==null?'':__t)+
'</div> </div> ';
 if(all_day.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-1"><b>'+
((__t=( cal.locale.all_day ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-11"> ';
 _.each(all_day, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' ';
 if(before_time.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-3"><b>'+
((__t=( cal.locale.before_time ))==null?'':__t)+
'</b></div> <div class="span5 col-xs-5"> ';
 _.each(before_time, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <span class="cal-hours pull-right">'+
((__t=( event.end_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' <div id="cal-day-panel" class="clearfix"> <div id="cal-day-panel-hour"> ';
 for(i = 0; i < hours; i++){ 
__p+=' <div class="cal-day-hour"> ';
 for(l = 0; l < cal._hour_min(i); l++){ 
__p+=' <div class="row-fluid cal-day-hour-part"> <div class="span1 col-xs-1"><b>'+
((__t=( cal._hour(i, l) ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-11"></div> </div> ';
 }; 
__p+=' </div> ';
 }; 
__p+=' </div> ';
 _.each(by_hour, function(event){ 
__p+=' <div class="pull-left day-event day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'" style="margin-top: '+
((__t=( (event.top * 30) ))==null?'':__t)+
'px; height: '+
((__t=( (event.lines * 30) ))==null?'':__t)+
'px"> <span class="cal-hours">'+
((__t=( event.start_hour ))==null?'':__t)+
' - '+
((__t=( event.end_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> ';
 if(after_time.length) {
__p+=' <div class="row-fluid clearfix cal-day-hour"> <div class="span1 col-xs-3"><b>'+
((__t=( cal.locale.after_time ))==null?'':__t)+
'</b></div> <div class="span11 col-xs-9"> ';
 _.each(after_time, function(event){ 
__p+=' <div class="day-highlight dh-'+
((__t=( event['class'] ))==null?'':__t)+
'"> <span class="cal-hours">'+
((__t=( event.start_hour ))==null?'':__t)+
'</span> <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="event-item"> '+
((__t=( event.title ))==null?'':__t)+
'</a> </div> ';
 }); 
__p+=' </div> </div> ';
 }; 
__p+=' </div> ';
}
return __p;
};
exports['year-month']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="pull-right" data-cal-date="'+
((__t=( data_day ))==null?'':__t)+
'" data-cal-view="month">'+
((__t=( month_name ))==null?'':__t)+
'</span> ';
 if (events.length > 0) { 
__p+=' <small class="cal-events-num badge badge-important pull-left">'+
((__t=( events.length ))==null?'':__t)+
'</small> <div class="hide events-list" data-cal-start="'+
((__t=( start ))==null?'':__t)+
'" data-cal-end="'+
((__t=( end ))==null?'':__t)+
'"> ';
 _.each(events, function(event) { 
__p+=' <a href="'+
((__t=( event.url ? event.url : 'javascript:void(0)' ))==null?'':__t)+
'" data-event-id="'+
((__t=( event.id ))==null?'':__t)+
'" data-event-class="'+
((__t=( event['class'] ))==null?'':__t)+
'" class="pull-left event '+
((__t=( event['class'] ))==null?'':__t)+
' event'+
((__t=( event.id ))==null?'':__t)+
'" data-toggle="tooltip" title="'+
((__t=( event.title ))==null?'':__t)+
'"></a> ';
 }); 
__p+=' </div> ';
 } 
__p+=' ';
}
return __p;
};
exports['year']=function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cal-year-box"> <div class="row row-fluid cal-before-eventlist"> <div class="span3 col-md-3 cal-cell" data-cal-row="-month1">'+
((__t=( cal._month(0) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month2">'+
((__t=( cal._month(1) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month3">'+
((__t=( cal._month(2) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month4">'+
((__t=( cal._month(3) ))==null?'':__t)+
'</div> </div> <div class="row row-fluid cal-before-eventlist"> <div class="span3 col-md-3 cal-cell" data-cal-row="-month1">'+
((__t=( cal._month(4) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month2">'+
((__t=( cal._month(5) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month3">'+
((__t=( cal._month(6) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month4">'+
((__t=( cal._month(7) ))==null?'':__t)+
'</div> </div> <div class="row row-fluid cal-before-eventlist"> <div class="span3 col-md-3 cal-cell" data-cal-row="-month1">'+
((__t=( cal._month(8) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month2">'+
((__t=( cal._month(9) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month3">'+
((__t=( cal._month(10) ))==null?'':__t)+
'</div> <div class="span3 col-md-3 cal-cell" data-cal-row="-month4">'+
((__t=( cal._month(11) ))==null?'':__t)+
'</div> </div> </div> ';
}
return __p;
};